import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { wrapper } from "./styles/section.module.scss"

import {
  problem,
  problem__wrapper,
  problem__wrapper__headers,
  questions,
  questions__row,
  questions__row__question,
  questions__left,
  questions__right,
  solving,
  solving__wrapper,
  solving__wrapper__headers,
  solving__wrapper__items,
  solving__wrapper__items__single,
} from "./styles/problem.module.scss"

import Problem1 from "./icons/animated/Problem1"
import Problem2 from "./icons/animated/Problem2"
import Problem3 from "./icons/animated/Problem3"
import Problem4 from "./icons/animated/Problem4"
import Solve1 from "./icons/animated/Solve1"
import Solve2 from "./icons/animated/Solve2"
import Solve3 from "./icons/animated/Solve3"
import { AnimatedSection } from "@components/shared"

const Problem: React.FC = (): JSX.Element => {
  let windowWidth = null
  if (typeof window !== `undefined`) {
    windowWidth = window.innerWidth
  }

  const [width, setWidth] = useState<number>(windowWidth)
  function handleWindowSizeChange() {
    setWidth(windowWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  let isMobile: boolean = width <= 768
  console.log("isMobile", isMobile)
  return (
    <div className={wrapper}>
      <div className={problem}>
        <div className={problem__wrapper}>
          <div className={problem__wrapper__headers}>
            <h1>Zapewne masz problem...</h1>
            <p>
              Jako młody programista na codzień mierzysz <br />
              się z poniższymi problemami:
            </p>
          </div>
        </div>
      </div>

      <React.Fragment>
        <AnimatedSection className={questions}>
          <div className={questions__row}>
            <div className={questions__row__question}>
              <p>
                <strong>Jak </strong>skutecznie mam się rozwijać i które
                umiejętności są istotne?
              </p>
              <Problem1 />
            </div>
            <div className={questions__row__question}>
              <Problem3 />
              <p>
                <strong>Gdzie </strong>znajdę doświadczonego kolegę, <br />
                który sprawdzi mój kod?
              </p>
            </div>
          </div>
          <div className={questions__row}>
            <div className={questions__row__question}>
              <p>
                <strong>Gdzie </strong>mogę znaleźć praktyczne zadania
                programistyczne, które stanowią wyzwanie <br />i zapewnią
                rozwój?
              </p>
              <Problem2 />
            </div>
            <div className={questions__row__question}>
              <Problem4 />
              <p>
                <strong>Gdzie </strong>znajdę osoby chętne <br />
                do współpracy przy projektach?
              </p>
            </div>
          </div>
        </AnimatedSection>
      </React.Fragment>
      <AnimatedSection className={solving}>
        <div className={solving__wrapper}>
          <div className={solving__wrapper__headers}>
            <h1>
              ...ale te wszystkie problemy są już rozwiązane w jednym miejscu
            </h1>
            <p>
              Każdy z tych problemów rozwiązuje inicjatywa, którą nazwałem{" "}
              <strong>Gladiatorzy Javascriptu</strong>.
            </p>
            <p>Można ją opisać w 3 głównych punktach:</p>
          </div>
          <div className={solving__wrapper__items}>
            <div className={solving__wrapper__items__single}>
              <Solve1 />
              <p>
                Jesteśmy pomocną i zmotywowaną społecznością JSowców na
                Discordzie.
              </p>
            </div>
            <div className={solving__wrapper__items__single}>
              <Solve2 />
              <p>
                Realizujemy trudne i wymagające zadania w code pairing z
                cotygodniowym code review.
              </p>
            </div>
            <div className={solving__wrapper__items__single}>
              <Solve3 />
              <p>
                Pracujemy w zgranych teamach nad ambitnymi projektami do
                portfolio.
              </p>
            </div>
          </div>
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Problem
