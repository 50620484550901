import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import {
  wrapper,
  wrapper__videos,
  wrapper__videos__video,
  wrapper__videos__video__content,
  wrapper__videos__video__image,
  wrapper__videos__video__image__overlay,
} from "./styles/video.module.scss"
import BG from "./icons/thumbnail.jpg"
import Play from "./icons/play.svg"
import { AnimatedSection } from "@components/shared"
import moment from "moment"

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}

interface Props {}

const Video: React.FC<Props> = (): JSX.Element => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(id: "cGFnZToyOQ==") {
          ACF_Videos {
            videos {
              date
              url
              description
            }
          }
        }
      }
    }
  `)
  const { videos } = WP.pageBy.ACF_Videos
  const lastVideos = videos.slice(Math.max(videos.length - 3, 0)).reverse()

  return (
    <AnimatedSection className={wrapper}>
      <div id="videos" style={{ position: "absolute", top: "150px" }} />
      <h1>Sprawdź moje ostatnie nagrania</h1>
      <p>
        Dzięki temu sprawdzisz ich słuszność, i sam zdecydujesz czy warto
        sięgnąć po moją pomoc!
      </p>
      <div className={wrapper__videos}>
        {lastVideos.map((el, i) => (
          <div className={wrapper__videos__video}>
            <div className={wrapper__videos__video__image}>
              <img src={BG}></img>
              <div className={wrapper__videos__video__image__overlay}>
                <a href={el.url} target="_blank">
                  <img alt="play" src={Play}></img>
                  <small>Kliknij, aby odtworzyć nagranie</small>
                </a>
              </div>
            </div>
            <div className={wrapper__videos__video__content}>
              <time>{el.date}</time>
              <p>{truncateString(el.description, 170)}</p>
            </div>
          </div>
        ))}
      </div>
    </AnimatedSection>
  )
}

export default Video
