import React from "react"
import {
  container,
  container__tech,
  container__tech__wrapper,
  container__tech__offer,
  container__tech__offer__inner,
} from "./styles/abouttask.module.scss"

import TS from "./icons/ts.svg"
import JS from "./icons/js.svg"
import Node from "./icons/node.svg"
import Postgre from "./icons/postgre.svg"
import ReactIcon from "./icons/react.svg"
import CSS from "./icons/css.svg"
import HTML from "./icons/html.svg"
import Next from "./icons/next-js.svg"
import Ex from "./icons/express.svg"
import Jest from "./icons/jest.svg"
import Testing from "./icons/testinglibrary.svg"
import Goat from "./icons/goat.png"

interface Props {}

const AboutTasks: React.FC<Props> = (): JSX.Element => {
  return (
    <div className={container}>
      <h1>Co znajdziesz w zbiorze?</h1>
      <div className={container__tech}>
        <div className={container__tech__offer}>
          <div className={container__tech__offer__inner}>
            <h4>Podzielony na 5 modułów:</h4>
            <ul>
              <li>
                <p>
                  <b>Fundamentals</b> - zagadnienia związane z programowaniem
                  obiektowym oraz funkcyjnym.
                </p>
                <div>
                  <img src={JS} alt="js" />
                  <img src={TS} alt="ts" />
                </div>
              </li>
              <li>
                <p>
                  <b>Frontend</b> - HTML, JS i CSS. Zadania wprowadzające jak i
                  rzadko poruszane funckjonalności JavaScriptu.
                </p>
                <div>
                  <img src={HTML} alt="html" />
                  <img src={CSS} alt="css" />
                  <img src={JS} alt="js" />
                </div>
              </li>
              <li>
                <p>
                  <b> React </b> - tworzenie komponentów oraz złożonej logiki.
                  np. Redux, React-router, custom hooks, Context API.
                </p>
                <div>
                  <img src={JS} alt="react" />
                  <img src={TS} alt="react" />
                  <img src={ReactIcon} alt="react" />
                </div>
              </li>
              <li>
                <p>
                  <b>Backend/Fullstack</b> - integracja z instniejącymi API,
                  tworzenie własnych endpointów. np. integracja z api google,
                  tworzenie róznego rodzaju widgetów.
                </p>
                <div>
                  <img src={Node} alt="node" />
                  <img src={Ex} alt="express" />
                  <img src={Next} alt="next" />
                </div>
              </li>
              <li>
                <p>
                  <b>Testy </b>- testowanie komponentów oraz logiki aplikacji.
                </p>
                <div>
                  <img src={Jest} alt="jest" />
                  <img src={Testing} alt="testing-library" />
                  <img src={Goat} alt="testing-library-goat" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutTasks

const icons = [HTML, CSS, JS, TS, Node, Ex, ReactIcon, Next, Postgre]

const textList = [
  [
    "Frontend - HTML, JS i CSS. Zadania wprowadzające jak i rzadko poruszane funckjonalności JavaScriptu.",
    "React - tworzenie komponentów oraz złożonej logiki. np. Redux, React-router, custom hooks, Context API.",
    "Backend/Fullstack - integracja z instniejącymi API, tworzenie własnych endpointów. np. integracja z api google, tworzenie róznego rodzaju widgetów.",
    "Testy - testowanie komponentów oraz logiki aplikacji.",
  ],
  [
    "118 zadań o róznym poziomie trudności.",
    "Każdy znajdzie tu coś dla siebie, zarówno początkujący jak i wyjadacze.",
    "Zadania opisane w prosty i zrozumiały sposób.",
    "Zadania, które realnie wpływają na Twój rozwój oraz poziom umiejętności.",
    "W razie problemów możesz się ze mną skontaktować.",
  ],
]
