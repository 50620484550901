import { useIntersectionObserver } from "@components/logic"
import React from "react"
import { useSpring, animated } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import animationConfig, { getAnimatedSvgProps } from "./animationConfig"
import { IAnimatedIcon } from "./icon"

const Problem4: React.FC = (): JSX.Element => {
  const { offset, pathRef } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()

  return (
    <div>
      <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={marktoObserve}
      >
        {typeof offset === "number" && inView ? (
          <AnimatedIcon offset={offset} />
        ) : (
          <path
            d="M63.9468 40.2645C63.951 31.5326 57.9875 23.9289 49.5064 21.8518C41.0253 19.7747 32.2229 23.7618 28.1911 31.5069C24.1592 39.2519 25.9423 48.7497 32.5087 54.505C32.4193 54.6556 32.3699 54.8268 32.3663 55.0021V63.4235C32.3699 66.3292 34.7246 68.6834 37.6298 68.687H37.7141C38.2342 72.3102 41.3383 75 44.9985 75C48.6588 75 51.7629 72.3102 52.283 68.687H52.3673C55.273 68.6834 57.6271 66.3292 57.6307 63.4235V55.0021C57.6271 54.8268 57.5783 54.6556 57.4889 54.505C61.5912 50.9091 63.9443 45.7197 63.9468 40.2645ZM28.1556 40.2645C28.1499 32.2954 33.7305 25.4133 41.5285 23.7711C49.3265 22.1294 57.2077 26.1772 60.4161 33.4719C63.6246 40.7667 61.2817 49.3115 54.8011 53.9494H35.1959C30.7811 50.7888 28.1602 45.694 28.1556 40.2645ZM55.5254 56.0548V58.1601H34.4717V56.0548H55.5254ZM34.4717 60.2655H55.5254V62.3709H34.4717V60.2655ZM44.9985 72.8977C42.4984 72.8946 40.3442 71.1362 39.8405 68.687H50.1566C49.6529 71.1362 47.4987 72.8946 44.9985 72.8977ZM52.3673 66.5816H37.6298C36.2918 66.5801 35.0993 65.7371 34.6516 64.4762H55.3455C54.8978 65.7371 53.7053 66.5801 52.3673 66.5816Z"
            fill="#1B2029"
            ref={pathRef}
          />
        )}
      </svg>
    </div>
  )
}

export default Problem4

const AnimatedIcon: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const { bgColor, fillColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(animationConfig.bg)
      await next(animationConfig.draw)
      await next(animationConfig.fill)
    },
    from: {
      x: offset,
      fillColor: animationConfig.bg.bgColor,
      bgColor: animationConfig.white,
    },
  })
  return (
    <>
      <animated.circle cx="45" cy="45" r="45" fill={bgColor} />
      <animated.path
        d="M57.6306 38.7906V38.8009C57.6964 39.3257 58.1441 39.7189 58.673 39.7169C58.7152 39.7184 58.7578 39.7153 58.799 39.7066C59.3777 39.6367 59.79 39.1108 59.7201 38.5326C59.6507 37.9543 59.1248 37.5416 58.5466 37.6115C57.9699 37.6876 57.5617 38.2129 57.6306 38.7906Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M58.0306 44.5595C57.493 44.3498 56.8854 44.6001 56.6516 45.128C56.0969 46.4582 55.3172 47.6836 54.3462 48.7492L54.338 48.7641C53.6863 49.4801 52.9548 50.1195 52.1581 50.669C51.6791 50.9995 51.5588 51.6553 51.8888 52.1339C52.2193 52.6129 52.8752 52.7332 53.3537 52.4027C54.2882 51.7581 55.1455 51.0077 55.9088 50.1673C57.0371 48.9229 57.9448 47.4955 58.593 45.9458C58.8186 45.4076 58.5673 44.7882 58.0306 44.5595Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M32.4506 38.2854C32.8932 34.6617 34.8767 31.4044 37.8929 29.3479C38.1247 29.1922 38.2851 28.9501 38.3386 28.6756C38.392 28.4011 38.3339 28.1163 38.1772 27.885C37.8405 27.4183 37.197 27.298 36.7138 27.6111C33.1857 29.9991 30.8696 33.8023 30.3663 38.0325C30.3324 38.3096 30.408 38.5892 30.5771 38.8118C30.7539 39.0282 31.0053 39.1711 31.2823 39.2117H31.4082C31.9397 39.2101 32.3869 38.8128 32.4506 38.2854Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M48.2093 28.0429C51.7384 28.9794 54.6924 31.3927 56.3146 34.6644C56.4945 35.0216 56.862 35.2457 57.2619 35.2431C57.4228 35.2472 57.5821 35.2107 57.725 35.1378C58.2468 34.8802 58.4626 34.2506 58.2097 33.7273C57.082 31.4621 55.393 29.5233 53.3041 28.0954C51.9168 27.1419 50.3742 26.4367 48.7459 26.0111C48.1836 25.8702 47.6104 26.2007 47.4511 26.7584C47.3827 27.0298 47.4244 27.3177 47.5667 27.5587C47.7071 27.7998 47.9384 27.9746 48.2093 28.0429Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M35.649 48.7521C35.2583 48.3214 34.5922 48.289 34.1614 48.6797C33.7307 49.0703 33.6983 49.7359 34.089 50.1672C34.8517 51.0076 35.7091 51.758 36.6436 52.4026C37.1226 52.7336 37.7785 52.6134 38.109 52.1343C38.4395 51.6558 38.3192 50.9994 37.8407 50.6689C37.0388 50.1163 36.3033 49.4733 35.649 48.7521Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M63.9468 40.2645C63.951 31.5326 57.9875 23.9289 49.5064 21.8518C41.0253 19.7747 32.2229 23.7618 28.1911 31.5069C24.1592 39.2519 25.9423 48.7497 32.5087 54.505C32.4193 54.6556 32.3699 54.8268 32.3663 55.0021V63.4235C32.3699 66.3292 34.7246 68.6834 37.6298 68.687H37.7141C38.2342 72.3102 41.3383 75 44.9985 75C48.6588 75 51.7629 72.3102 52.283 68.687H52.3673C55.273 68.6834 57.6271 66.3292 57.6307 63.4235V55.0021C57.6271 54.8268 57.5783 54.6556 57.4889 54.505C61.5912 50.9091 63.9443 45.7197 63.9468 40.2645ZM28.1556 40.2645C28.1499 32.2954 33.7305 25.4133 41.5285 23.7711C49.3265 22.1294 57.2077 26.1772 60.4161 33.4719C63.6246 40.7667 61.2817 49.3115 54.8011 53.9494H35.1959C30.7811 50.7888 28.1602 45.694 28.1556 40.2645ZM55.5254 56.0548V58.1601H34.4717V56.0548H55.5254ZM34.4717 60.2655H55.5254V62.3709H34.4717V60.2655ZM44.9985 72.8977C42.4984 72.8946 40.3442 71.1362 39.8405 68.687H50.1566C49.6529 71.1362 47.4987 72.8946 44.9985 72.8977ZM52.3673 66.5816H37.6298C36.2918 66.5801 35.0993 65.7371 34.6516 64.4762H55.3455C54.8978 65.7371 53.7053 66.5801 52.3673 66.5816Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M44.9985 20.2634C45.5798 20.2634 46.0512 19.7921 46.0512 19.2107V16.0527C46.0512 15.4713 45.5798 15 44.9985 15C44.4171 15 43.9458 15.4713 43.9458 16.0527V19.2107C43.9458 19.7921 44.4171 20.2634 44.9985 20.2634Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M29.3671 26.1216C29.6318 26.3956 30.023 26.505 30.391 26.4089C30.759 26.3123 31.0464 26.025 31.143 25.6569C31.2391 25.2889 31.1296 24.8972 30.8557 24.633L28.9302 22.7076C28.5174 22.3087 27.8605 22.3144 27.455 22.7204C27.0489 23.1265 27.0428 23.7834 27.4421 24.1961L29.3671 26.1216Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M20.7866 41.3172H23.9446C24.526 41.3172 24.9973 40.8458 24.9973 40.2645C24.9973 39.6831 24.526 39.2118 23.9446 39.2118H20.7866C20.2052 39.2118 19.7339 39.6831 19.7339 40.2645C19.7339 40.8458 20.2052 41.3172 20.7866 41.3172Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M28.1865 58.1297C28.4656 58.1292 28.7329 58.0187 28.9302 57.8213L30.8557 55.8958C31.1297 55.6316 31.2392 55.24 31.143 54.8719C31.0464 54.5039 30.7591 54.2166 30.3911 54.1199C30.023 54.0238 29.6319 54.1333 29.3672 54.4073L27.4422 56.3327C27.141 56.6334 27.051 57.0863 27.214 57.4795C27.3769 57.8727 27.7604 58.1292 28.1865 58.1297Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M59.1414 54.407C58.7302 54.8182 58.7302 55.4844 59.1414 55.8956L61.0669 57.8211C61.3311 58.0945 61.7222 58.204 62.0903 58.1079C62.4588 58.0118 62.7461 57.7239 62.8422 57.3559C62.9389 56.9879 62.8289 56.5967 62.5554 56.3325L60.63 54.407C60.2188 53.9958 59.5526 53.9958 59.1414 54.407Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M66.0522 41.3172H69.2102C69.7916 41.3172 70.2629 40.8458 70.2629 40.2645C70.2629 39.6831 69.7916 39.2118 69.2102 39.2118H66.0522C65.4709 39.2118 64.9995 39.6831 64.9995 40.2645C64.9995 40.8458 65.4709 41.3172 66.0522 41.3172Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M59.8857 26.4301C60.1648 26.4301 60.4326 26.3191 60.63 26.1217L62.5555 24.1962C62.8289 23.932 62.9389 23.5409 62.8423 23.1728C62.7461 22.8048 62.4588 22.517 62.0903 22.4209C61.7222 22.3247 61.3311 22.4342 61.0669 22.7077L59.1414 24.6331C58.8402 24.9343 58.7503 25.3872 58.9132 25.7804C59.0761 26.1736 59.4601 26.4301 59.8857 26.4301Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M42.893 26.5794C42.893 27.1608 42.4216 27.6321 41.8403 27.6321C41.2589 27.6321 40.7876 27.1608 40.7876 26.5794C40.7876 25.9981 41.2589 25.5267 41.8403 25.5267C42.4216 25.5267 42.893 25.9981 42.893 26.5794Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M33.4188 45.5277C33.4188 46.109 32.9475 46.5803 32.3662 46.5803C31.7848 46.5803 31.3135 46.109 31.3135 45.5277C31.3135 44.9463 31.7848 44.475 32.3662 44.475C32.9475 44.475 33.4188 44.9463 33.4188 45.5277Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
    </>
  )
}
