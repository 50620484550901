import React from "react"
import { Link } from "gatsby"
import { wrapper } from "./styles/section.module.scss"

import {
  gladiators,
  gladiators__wrapper,
  gladiators__wrapper__options,
  gladiators__wrapper__options__single,
} from "./styles/gladiators.module.scss"

import Gladiators1 from "./icons/animated/Gladiators1"
import Gladiators2 from "./icons/animated/Gladiators2"
import Gladiators3 from "./icons/animated/Gladiators3"
import { AnimatedSection } from "@components/shared"

const Gladiators: React.FC = (): JSX.Element => {
  return (
    <div className={wrapper}>
      <AnimatedSection className={gladiators}>
        <div className={gladiators__wrapper}>
          <h1>Gladiatorzy to</h1>
          <div className={gladiators__wrapper__options}>
            <div className={gladiators__wrapper__options__single}>
              <Gladiators1 />
              <p>
                Świadoma i zmotywowana społeczność młodych
                <br /> JS devów.
              </p>
            </div>
            <div className={gladiators__wrapper__options__single}>
              <Gladiators2 />
              <p>
                Trudne zadania techniczne, przy których rekrutacja to pestka.
              </p>
            </div>
            <div className={gladiators__wrapper__options__single}>
              <Gladiators3 />
              <p>
                Unikatowe projekty do portfolio pozwalające się wybić w tłumie
                todolist.
              </p>
            </div>
          </div>

          <h3>
            Lepiej uczyć się w zgranej grupie, <br />
            niż samemu!
          </h3>
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Gladiators
