import React from "react"
import { Link } from "gatsby"
import { wrapper } from "./styles/section.module.scss"

import {
  meet,
  meet__wrapper,
  meet__wrapper__quote,
  programming,
  programming__headers,
  programming__wrapper,
  programming__wrapper__item,
  programming__wrapper__item__stat,
  programming__wrapper__item__stat__yellow,
} from "./styles/meet.module.scss"

import Przemek from "./icons/przemek.png"
import { AnimatedSection } from "@components/shared"

const Meet: React.FC = (): JSX.Element => {
  return (
    <div className={wrapper}>
      <AnimatedSection className={meet}>
        <h1> Kto to wszystko rozpoczął? </h1>

        <div style={{ position: "absolute", top: "-200px" }} id="meet" />
        <div className={meet__wrapper}>
          <p>
            ”Chcę być takim mentorem <br /> jakiego sam chciałbym <br />
            mieć jak zaczynalem swoją <br /> przygodę z programowaniem <br />{" "}
            prawie 10 lat temu."
          </p>
          <img src={Przemek} alt="przemek"></img>
        </div>
      </AnimatedSection>
      <AnimatedSection className={programming}>
        <div className={programming__headers}>
          <h1>Programuję...</h1>
          <p>...bo to zajebiście lubię!</p>
        </div>
        <div className={programming__wrapper}>
          <div className={programming__wrapper__item}>
            <div className={programming__wrapper__item__stat}>
              <h2 className={programming__wrapper__item__stat__yellow}>
                <span>1+</span>
              </h2>
              <h2>milion</h2>
            </div>

            <p>
              Wymienionych ze studentami wiadomościa na slacku <br />i
              messengerze.
            </p>
          </div>
          <div className={programming__wrapper__item}>
            <div className={programming__wrapper__item__stat}>
              <h2 className={programming__wrapper__item__stat__yellow}>
                od <span>4 lat</span>
              </h2>
            </div>
            <p>Prowadzę mentorską szkołę programowania Localhost Academy.</p>
          </div>
          <div className={programming__wrapper__item}>
            <div className={programming__wrapper__item__stat}>
              <h2>ponad</h2>
              <h2 className={programming__wrapper__item__stat__yellow}>
                <span>100 osób</span>
              </h2>
            </div>
            <p>Samodzielnie wprowadziłem do świata programowania.</p>
          </div>
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Meet
