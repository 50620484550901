import { useIntersectionObserver } from "@components/logic"
import React from "react"
import { animated, useSpring } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import { guaranteeAnimationConfig, sectionSvgProps } from "./animationConfig"
import { IAnimatedIcon } from "./icon"

const GuaranteeSVG = () => {
  const { pathRef, offset } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()

  return (
    <svg
      width="400"
      height="358"
      viewBox="0 0 400 358"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      <path
        d="M3.54321 294.578C7.1817 316.943 23.5751 334.26 41.0278 349.394C43.4646 351.508 45.9072 353.58 48.3556 355.611C48.3727 355.621 48.3892 355.64 48.4064 355.65C48.5243 355.748 48.6424 355.845 48.7514 355.942C49.2568 356.36 49.7624 356.779 50.2637 357.193L49.9492 357.291L48.9694 357.592C49.3057 357.499 49.6375 357.411 49.9738 357.319C50.074 357.291 50.1783 357.267 50.2783 357.238C61.8497 354.116 73.6565 350.689 83.116 343.374C92.93 335.776 99.6888 322.824 96.3041 310.889C95.8586 309.326 95.2399 307.817 94.4593 306.391C94.1402 305.793 93.7885 305.216 93.4186 304.647C91.1821 301.137 88.0502 298.286 84.3449 296.386C80.6397 294.487 76.4948 293.607 72.3365 293.838C68.1782 294.069 64.1565 295.403 60.6851 297.701C57.2136 300 54.4176 303.181 52.5846 306.916C52.7497 293.767 64.7729 284.178 76.05 277.391C87.3311 270.61 100.104 263.283 103.651 250.612C105.628 243.562 103.965 236.301 100.082 230.167C99.9613 229.98 99.8409 229.793 99.7155 229.611C94.9535 222.437 87.6577 217.322 79.2854 215.287C63.6633 211.747 47.2912 218.268 34.4775 227.877C13.8547 243.336 -0.592552 269.149 3.54321 294.578Z"
        fill="#F2F2F2"
      />
      <path
        d="M36.6778 269.872C33.0744 274.226 30.1258 279.083 27.9253 284.287C26.0333 288.948 24.8494 293.865 24.4129 298.876C23.4638 309.169 25.4182 319.572 29.3162 329.097C32.3484 336.329 36.2835 343.149 41.0277 349.394C43.4645 351.508 45.9071 353.58 48.3556 355.611C48.3726 355.621 48.3891 355.64 48.4064 355.65C48.5242 355.748 48.6423 355.845 48.7513 355.942C49.2567 356.36 49.7623 356.779 50.2637 357.193L49.9492 357.291L48.9693 357.592C49.3057 357.5 49.6374 357.412 49.9737 357.319C50.0739 357.291 50.1782 357.267 50.2782 357.239C50.2631 349.018 52.2205 340.914 55.9864 333.605C59.7523 326.296 65.217 319.995 71.9226 315.23C78.6326 310.551 86.3548 307.522 94.4593 306.391C94.1402 305.794 93.7885 305.216 93.4186 304.647C90.2826 305.133 87.1957 305.895 84.1941 306.924C73.4678 310.572 64.2023 317.574 57.7691 326.892C51.9835 335.36 48.7364 345.301 48.409 355.548C48.0049 355.083 47.6013 354.609 47.2109 354.141C39.7633 345.324 33.3923 335.464 29.5951 324.512C26.275 315.224 25.292 305.264 26.7326 295.507C28.4273 284.884 33.8725 275.509 41.0705 267.642C48.7799 259.224 58.0368 252.068 67.7243 246.069C77.6483 239.912 88.2892 234.991 99.4098 231.416C99.6609 231.333 99.8706 231.158 99.9957 230.925C100.121 230.693 100.152 230.421 100.082 230.167C100.038 229.941 99.9061 229.741 99.7154 229.611C99.6163 229.553 99.5053 229.519 99.3909 229.51C99.2765 229.501 99.1616 229.518 99.0547 229.56C97.6816 230.002 96.3165 230.454 94.9587 230.933C83.6488 234.923 72.8789 240.302 62.8966 246.945C53.2565 253.344 44.0008 260.865 36.6778 269.872Z"
        fill="white"
      />
      <path
        d="M233.371 218.799C234.483 218.798 235.553 218.374 236.364 217.614C237.175 216.854 237.667 215.814 237.74 214.705L238.391 204.78C238.467 203.62 238.078 202.478 237.312 201.605C236.545 200.731 235.462 200.198 234.302 200.122L156.46 195.03C155.233 194.949 154.002 195.11 152.837 195.505C151.672 195.899 150.596 196.519 149.671 197.329C148.746 198.139 147.99 199.124 147.446 200.226C146.902 201.328 146.581 202.526 146.5 203.752C146.42 204.978 146.582 206.208 146.978 207.372C147.373 208.535 147.995 209.609 148.806 210.533C149.617 211.457 150.603 212.211 151.706 212.754C152.81 213.297 154.009 213.618 155.237 213.698L233.078 218.79C233.176 218.796 233.273 218.799 233.371 218.799Z"
        fill="#F8DF2A"
      />
      <path
        d="M218.022 220.079C219.156 220.078 220.245 219.637 221.061 218.851C221.878 218.065 222.358 216.994 222.402 215.862L222.956 201.037C222.978 200.462 222.886 199.889 222.685 199.35C222.485 198.81 222.18 198.316 221.789 197.894C221.397 197.472 220.926 197.132 220.403 196.892C219.879 196.652 219.314 196.518 218.738 196.497L154.727 194.107C153.565 194.063 152.433 194.483 151.581 195.273C150.728 196.063 150.225 197.16 150.182 198.321L149.627 213.145C149.605 213.72 149.698 214.294 149.898 214.833C150.098 215.372 150.403 215.867 150.795 216.289C151.186 216.71 151.657 217.051 152.181 217.29C152.704 217.53 153.27 217.665 153.845 217.686L217.856 220.076C217.911 220.078 217.967 220.079 218.022 220.079Z"
        fill="#2F2E41"
      />
      <path
        d="M143.011 356.737H152.968C154.131 356.735 155.245 356.273 156.068 355.452C156.89 354.631 157.352 353.518 157.353 352.356V269.455C157.352 268.293 156.89 267.18 156.068 266.359C155.245 265.537 154.131 265.076 152.968 265.074H143.011C141.849 265.076 140.734 265.537 139.912 266.359C139.09 267.18 138.628 268.293 138.626 269.455V352.356C138.628 353.518 139.09 354.631 139.912 355.452C140.734 356.273 141.849 356.735 143.011 356.737Z"
        fill="#2F2E41"
      />
      <path
        d="M187.207 312.916L195.721 307.76C196.715 307.157 197.428 306.185 197.705 305.057C197.982 303.929 197.8 302.737 197.198 301.744L154.183 230.849C153.579 229.856 152.606 229.144 151.477 228.867C150.347 228.59 149.155 228.772 148.16 229.373L139.645 234.529C138.651 235.132 137.938 236.104 137.661 237.232C137.384 238.36 137.567 239.552 138.168 240.546L181.184 311.441C181.788 312.433 182.761 313.146 183.89 313.422C185.019 313.699 186.212 313.517 187.207 312.916Z"
        fill="#2F2E41"
      />
      <path
        d="M158.134 178.558C139.133 178.558 123.729 163.17 123.729 144.188C123.729 125.206 139.133 109.818 158.134 109.818C177.135 109.818 192.539 125.206 192.539 144.188C192.539 163.17 177.135 178.558 158.134 178.558Z"
        fill="#F8DF2A"
      />
      <path
        d="M176.757 155.923C177.864 155.56 178.88 154.964 179.737 154.176C180.304 153.613 180.742 152.933 181.019 152.183C181.296 151.434 181.406 150.633 181.34 149.836C181.304 149.269 181.138 148.718 180.853 148.225C180.568 147.733 180.174 147.313 179.699 146.999C178.469 146.21 176.824 146.208 175.148 146.945L175.212 133.528L173.862 133.521L173.788 149.295L174.827 148.642C176.033 147.886 177.754 147.353 178.971 148.133C179.271 148.339 179.52 148.612 179.698 148.929C179.877 149.247 179.979 149.602 179.997 149.965C180.043 150.559 179.96 151.155 179.754 151.713C179.548 152.271 179.224 152.779 178.805 153.201C177.315 154.623 175.141 155.067 172.663 155.464L172.876 156.795C174.193 156.621 175.492 156.329 176.757 155.923Z"
        fill="#2F2E41"
      />
      <path
        d="M189.12 132.85L189.297 134.186L182.093 135.136L181.916 133.8L189.12 132.85Z"
        fill="#2F2E41"
      />
      <path
        d="M166.381 135.849L166.557 137.185L159.353 138.135L159.176 136.799L166.381 135.849Z"
        fill="#2F2E41"
      />
      <path
        d="M129.406 291.698H187.422C188.942 291.696 190.4 291.092 191.475 290.018C192.55 288.944 193.154 287.488 193.156 285.969L181.01 192.925C181.001 191.413 180.392 189.967 179.318 188.902C178.245 187.836 176.792 187.239 175.279 187.24H154.69C146.466 187.25 138.582 190.517 132.767 196.326C126.952 202.135 123.681 210.012 123.672 218.227V285.969C123.673 287.488 124.278 288.944 125.353 290.018C126.428 291.092 127.885 291.696 129.406 291.698Z"
        fill="#2F2E41"
      />
      <path
        d="M144.015 286.737C144.368 287.506 144.935 288.157 145.649 288.611C146.364 289.065 147.194 289.303 148.041 289.296L157.997 289.21C158.573 289.205 159.142 289.087 159.672 288.862C160.202 288.637 160.683 288.31 161.087 287.9C161.49 287.49 161.809 287.004 162.025 286.471C162.24 285.937 162.349 285.367 162.344 284.792L161.667 206.866C161.657 205.638 161.404 204.424 160.924 203.293C160.443 202.162 159.745 201.137 158.868 200.276C157.991 199.415 156.953 198.735 155.813 198.275C154.673 197.815 153.453 197.583 152.223 197.594C150.994 197.605 149.779 197.857 148.647 198.337C147.515 198.817 146.489 199.515 145.627 200.391C144.765 201.267 144.084 202.304 143.623 203.443C143.163 204.582 142.931 205.8 142.942 207.028L143.618 284.954C143.622 285.57 143.758 286.177 144.015 286.737Z"
        fill="#F8DF2A"
      />
      <path
        d="M139.616 265.935C139.969 266.704 140.537 267.354 141.251 267.808C141.965 268.262 142.795 268.5 143.642 268.494L160.648 268.346C161.811 268.335 162.921 267.863 163.736 267.035C164.551 266.207 165.004 265.089 164.995 263.928L164.439 199.94C164.435 199.365 164.316 198.796 164.091 198.267C163.866 197.737 163.539 197.257 163.128 196.854C162.718 196.451 162.232 196.132 161.698 195.917C161.164 195.701 160.593 195.593 160.017 195.598L143.011 195.745C142.435 195.75 141.866 195.868 141.336 196.093C140.805 196.318 140.325 196.645 139.921 197.055C139.518 197.465 139.199 197.951 138.983 198.484C138.767 199.018 138.659 199.588 138.664 200.163L139.219 264.151C139.224 264.767 139.359 265.375 139.616 265.935Z"
        fill="#2F2E41"
      />
      <path
        d="M157.491 123.803C162.09 120.001 168.238 123.597 173.439 123.087C178.415 122.599 182.42 118.224 183.512 113.516C184.785 108.024 182.025 102.445 177.867 98.927C173.314 95.0736 167.2 93.8762 161.374 94.4625C154.696 95.1346 148.544 98.1213 143.006 101.77C137.662 105.18 132.731 109.196 128.311 113.738C124.351 117.944 120.967 122.983 119.808 128.722C118.755 133.938 119.373 139.751 122.385 144.244C123.985 146.547 126.151 148.4 128.676 149.624C131.307 150.977 134.122 151.962 136.667 153.484C140.515 155.785 144.208 160.475 142.898 165.257C142.616 166.302 142.082 167.262 141.343 168.052C140.454 169.001 138.908 167.695 139.799 166.744C141.364 165.073 141.347 162.805 140.569 160.759C139.641 158.454 137.956 156.531 135.792 155.307C133.128 153.718 130.172 152.712 127.439 151.258C124.821 149.928 122.564 147.986 120.859 145.597C117.642 140.948 116.768 134.96 117.622 129.452C118.546 123.492 121.723 118.121 125.662 113.643C129.949 108.771 135.179 104.677 140.512 101.011C146.234 97.0775 152.536 93.7798 159.462 92.6752C165.465 91.7177 171.937 92.5002 177.107 95.8565C181.932 98.9895 185.578 104.29 185.839 110.141C185.922 112.803 185.294 115.438 184.019 117.776C182.744 120.115 180.868 122.072 178.585 123.445C176.243 124.785 173.54 125.357 170.855 125.08C167.984 124.842 165.124 123.908 162.227 124.097C160.916 124.152 159.659 124.632 158.646 125.464C157.641 126.295 156.494 124.627 157.491 123.803V123.803Z"
        fill="#2F2E41"
      />
      <path
        d="M197.946 302.941C197.934 302.865 197.919 302.789 197.903 302.714C197.783 302.151 197.552 301.617 197.225 301.144C196.897 300.671 196.478 300.267 195.993 299.956L187.626 294.565C186.648 293.937 185.461 293.721 184.325 293.966C183.188 294.211 182.196 294.897 181.564 295.872L155.753 335.848C155.123 336.825 154.907 338.011 155.152 339.147C155.397 340.282 156.084 341.274 157.061 341.904L165.429 347.295C166.406 347.924 167.594 348.139 168.73 347.894C169.866 347.649 170.859 346.964 171.49 345.989L197.302 306.012C197.892 305.104 198.121 304.009 197.946 302.941Z"
        fill="#2F2E41"
      />

      <path
        d="M0.664597 357.894H245.265C245.441 357.894 245.61 357.824 245.735 357.7C245.86 357.575 245.93 357.407 245.93 357.23C245.93 357.054 245.86 356.885 245.735 356.761C245.61 356.636 245.441 356.566 245.265 356.566H0.664597C0.488312 356.566 0.31926 356.636 0.194611 356.761C0.0699615 356.885 -7.62939e-05 357.054 -7.62939e-05 357.23C-7.62939e-05 357.407 0.0699615 357.575 0.194611 357.7C0.31926 357.824 0.488312 357.894 0.664597 357.894Z"
        fill="#3F3D56"
      />
      {typeof offset === "number" && inView ? (
        <Animated offset={offset} />
      ) : (
        <path
          d="M229.283 39.4744C229.926 37.9496 231.002 36.6464 232.379 35.7264C233.755 34.8063 235.372 34.3096 237.028 34.2979H391.62C393.842 34.299 395.973 35.1814 397.544 36.7511C399.116 38.3209 399.999 40.4495 400 42.6694V238.044C399.999 240.263 399.116 242.392 397.544 243.962C395.973 245.532 393.842 246.414 391.62 246.415H237.022C234.801 246.412 232.671 245.529 231.1 243.96C229.529 242.391 228.645 240.263 228.642 238.044V42.6694C228.638 41.5728 228.854 40.4864 229.277 39.4744H229.283ZM230.509 238.044C230.511 239.77 231.198 241.425 232.42 242.646C233.642 243.867 235.299 244.554 237.028 244.556H391.62C393.349 244.556 395.006 243.87 396.229 242.649C397.451 241.428 398.138 239.772 398.139 238.045V42.6705C398.137 40.944 397.449 39.2889 396.227 38.0681C395.005 36.8473 393.348 36.1604 391.62 36.1581H237.022C235.72 36.163 234.449 36.5553 233.372 37.285C232.294 38.0147 231.458 39.0486 230.971 40.2548C230.876 40.4951 230.794 40.7404 230.726 40.9895C230.578 41.5376 230.503 42.1028 230.503 42.6705L230.509 238.044Z"
          fill="#3F3D56"
          ref={pathRef}
        />
      )}
    </svg>
  )
}

export default GuaranteeSVG

const Animated: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const {
    lineColor,
    x,
    innerLinesColor,
    darkLinesColor,
    yellowFigureColor,
  }: any = useSpring({
    to: async (next: any) => {
      await next(guaranteeAnimationConfig.drawLine)
      await next(guaranteeAnimationConfig.fillColor)
      await next(guaranteeAnimationConfig.fillLinesColor)
    },
    from: {
      x: offset,
      darkLinesColor: guaranteeAnimationConfig.white,
      innerLinesColor: guaranteeAnimationConfig.white,
      lineColor: guaranteeAnimationConfig.white,
      yellowFigureColor: guaranteeAnimationConfig.white,
    },
  })
  console.log(lineColor, innerLinesColor)
  return (
    <>
      <animated.path
        d="M261.544 80.4251H308.109C309.097 80.4251 310.045 80.033 310.744 79.335C311.442 78.6371 311.835 77.6905 311.835 76.7035C311.835 75.7165 311.442 74.7699 310.744 74.072C310.045 73.374 309.097 72.9819 308.109 72.9819H261.544C260.556 72.9819 259.608 73.374 258.91 74.072C258.211 74.7699 257.819 75.7165 257.819 76.7035C257.819 77.6905 258.211 78.6371 258.91 79.335C259.608 80.033 260.556 80.4251 261.544 80.4251Z"
        fill={darkLinesColor}
      />
      <animated.path
        d="M261.544 99.9627H308.109C308.599 99.9633 309.084 99.8675 309.537 99.6808C309.989 99.4941 310.4 99.22 310.747 98.8744C311.093 98.5288 311.368 98.1183 311.556 97.6664C311.743 97.2146 311.84 96.7302 311.84 96.2411C311.84 95.7519 311.743 95.2676 311.556 94.8158C311.368 94.3639 311.093 93.9534 310.747 93.6078C310.4 93.2621 309.989 92.9881 309.537 92.8014C309.084 92.6146 308.599 92.5189 308.109 92.5195H261.544C261.055 92.5189 260.57 92.6146 260.117 92.8014C259.664 92.9881 259.253 93.2621 258.907 93.6078C258.56 93.9534 258.285 94.3639 258.098 94.8158C257.91 95.2676 257.814 95.7519 257.814 96.2411C257.814 96.7302 257.91 97.2146 258.098 97.6664C258.285 98.1183 258.56 98.5288 258.907 98.8744C259.253 99.22 259.664 99.4941 260.117 99.6808C260.57 99.8675 261.055 99.9633 261.544 99.9627Z"
        fill={darkLinesColor}
      />
      <animated.path
        d="M332.002 111.087H366.031C367.143 111.086 368.208 110.645 368.994 109.86C369.78 109.075 370.221 108.01 370.223 106.9V66.7836C370.221 65.6735 369.78 64.6092 368.994 63.8243C368.208 63.0393 367.143 62.5979 366.031 62.5967H332.002C330.891 62.5979 329.825 63.0393 329.039 63.8243C328.254 64.6092 327.812 65.6735 327.811 66.7836V106.9C327.812 108.01 328.254 109.075 329.039 109.86C329.825 110.645 330.891 111.086 332.002 111.087Z"
        fill={yellowFigureColor}
      />
      <animated.path
        d="M261.867 139.968H367.105C368.093 139.968 369.04 139.576 369.739 138.878C370.438 138.18 370.83 137.233 370.83 136.246C370.83 135.259 370.438 134.313 369.739 133.615C369.04 132.917 368.093 132.525 367.105 132.525H261.867C260.879 132.525 259.932 132.917 259.233 133.615C258.534 134.313 258.142 135.259 258.142 136.246C258.142 137.233 258.534 138.18 259.233 138.878C259.932 139.576 260.879 139.968 261.867 139.968Z"
        fill={innerLinesColor}
      />
      <animated.path
        d="M261.867 159.506H367.105C368.093 159.506 369.04 159.114 369.739 158.416C370.438 157.718 370.83 156.771 370.83 155.784C370.83 154.797 370.438 153.85 369.739 153.153C369.04 152.455 368.093 152.062 367.105 152.062H261.867C260.879 152.062 259.932 152.455 259.233 153.153C258.534 153.85 258.142 154.797 258.142 155.784C258.142 156.771 258.534 157.718 259.233 158.416C259.932 159.114 260.879 159.506 261.867 159.506Z"
        fill={innerLinesColor}
      />
      <animated.path
        d="M261.867 179.043H367.105C368.092 179.041 369.038 178.649 369.736 177.951C370.433 177.253 370.825 176.307 370.825 175.321C370.825 174.335 370.433 173.389 369.736 172.691C369.038 171.994 368.092 171.601 367.105 171.6H261.867C261.378 171.599 260.893 171.695 260.44 171.881C259.988 172.068 259.576 172.342 259.23 172.688C258.883 173.034 258.609 173.444 258.421 173.896C258.234 174.348 258.137 174.832 258.137 175.321C258.137 175.81 258.234 176.295 258.421 176.747C258.609 177.198 258.883 177.609 259.23 177.954C259.576 178.3 259.988 178.574 260.44 178.761C260.893 178.948 261.378 179.043 261.867 179.043Z"
        fill={innerLinesColor}
      />
      <animated.path
        d="M261.867 198.58H367.105C368.093 198.58 369.04 198.188 369.739 197.49C370.438 196.792 370.83 195.846 370.83 194.859C370.83 193.872 370.438 192.925 369.739 192.227C369.04 191.529 368.093 191.137 367.105 191.137H261.867C260.879 191.137 259.932 191.529 259.233 192.227C258.534 192.925 258.142 193.872 258.142 194.859C258.142 195.846 258.534 196.792 259.233 197.49C259.932 198.188 260.879 198.58 261.867 198.58Z"
        fill={innerLinesColor}
      />
      <animated.path
        d="M261.867 218.117H367.105C368.093 218.117 369.04 217.725 369.739 217.027C370.438 216.33 370.83 215.383 370.83 214.396C370.83 213.409 370.438 212.462 369.739 211.764C369.04 211.066 368.093 210.674 367.105 210.674H261.867C260.879 210.674 259.932 211.066 259.233 211.764C258.534 212.462 258.142 213.409 258.142 214.396C258.142 215.383 258.534 216.33 259.233 217.027C259.932 217.725 260.879 218.117 261.867 218.117Z"
        fill={innerLinesColor}
      />
      <animated.path
        d="M229.283 39.4744C229.926 37.9496 231.002 36.6464 232.379 35.7264C233.755 34.8063 235.372 34.3096 237.028 34.2979H391.62C393.842 34.299 395.973 35.1814 397.544 36.7511C399.116 38.3209 399.999 40.4495 400 42.6694V238.044C399.999 240.263 399.116 242.392 397.544 243.962C395.973 245.532 393.842 246.414 391.62 246.415H237.022C234.801 246.412 232.671 245.529 231.1 243.96C229.529 242.391 228.645 240.263 228.642 238.044V42.6694C228.638 41.5728 228.854 40.4864 229.277 39.4744H229.283ZM230.509 238.044C230.511 239.77 231.198 241.425 232.42 242.646C233.642 243.867 235.299 244.554 237.028 244.556H391.62C393.349 244.556 395.006 243.87 396.229 242.649C397.451 241.428 398.138 239.772 398.139 238.045V42.6705C398.137 40.944 397.449 39.2889 396.227 38.0681C395.005 36.8473 393.348 36.1604 391.62 36.1581H237.022C235.72 36.163 234.449 36.5553 233.372 37.285C232.294 38.0147 231.458 39.0486 230.971 40.2548C230.876 40.4951 230.794 40.7404 230.726 40.9895C230.578 41.5376 230.503 42.1028 230.503 42.6705L230.509 238.044Z"
        {...sectionSvgProps(lineColor, x, offset)}
      />
      <animated.path
        d="M228.127 71.8309C208.271 71.8309 192.175 55.751 192.175 35.9154C192.175 16.0799 208.271 0 228.127 0C247.983 0 264.079 16.0799 264.079 35.9154C264.079 55.751 247.983 71.8309 228.127 71.8309Z"
        fill={yellowFigureColor}
      />
      <animated.path
        d="M231.69 49.6162C232.499 49.6176 233.286 49.3562 233.933 48.8715L233.973 48.8414L242.423 42.3846C242.814 42.0849 243.142 41.7111 243.389 41.2847C243.636 40.8582 243.796 40.3873 243.86 39.899C243.925 39.4106 243.893 38.9144 243.765 38.4385C243.638 37.9627 243.418 37.5165 243.118 37.1256C242.818 36.7347 242.444 36.4066 242.017 36.1602C241.59 35.9137 241.119 35.7536 240.63 35.6891C240.141 35.6246 239.644 35.657 239.168 35.7842C238.691 35.9115 238.245 36.1313 237.854 36.431L232.381 40.6235L219.448 23.769C219.149 23.3782 218.775 23.0503 218.348 22.8039C217.921 22.5575 217.45 22.3975 216.961 22.3331C216.473 22.2686 215.976 22.3009 215.5 22.4281C215.024 22.5554 214.577 22.775 214.186 23.0746L214.185 23.0754L214.265 23.1868L214.183 23.0754C213.394 23.6813 212.878 24.5752 212.747 25.5609C212.617 26.5466 212.884 27.5437 213.489 28.3333L228.7 48.1489C229.052 48.6055 229.504 48.975 230.022 49.2287C230.54 49.4823 231.11 49.6134 231.687 49.6117L231.69 49.6162Z"
        fill="white"
      />
    </>
  )
}
