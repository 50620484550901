import React, { useRef } from "react"
import { Link } from "gatsby"
import { withHtml } from "@components/logic"
import {
  ResizedImage,
  Modal,
  AnimatedButton,
  AnimatedSection,
} from "@components/shared"
import { ProductModal } from "@components/ecommerce"

import { wrapper } from "./styles/section.module.scss"
import AddHomeProduct from "../Home/common/AddHomeProduct"
import { offer__wrapper__products__item__button } from "./styles/offer.module.scss"
import Spartan from "./icons/spartan_small_tin.png"
const Description = withHtml("span", "span")
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}
import { offer, offer__modal } from "./styles/offer.module.scss"

import {
  demo,
  demo__wrapper,
  demo__wrapper__headers,
  demo__wrapper__product,
  demo__wrapper__product__img,
  demo__wrapper__product__button,
} from "./styles/demo.module.scss"

const Demo: React.FC = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={wrapper}>
        <AnimatedSection className={demo}>
          <div className={demo__wrapper}>
            <div className={demo__wrapper__headers}>
              <h1>
                Nie kupuj kota <br />w worku!
              </h1>
              <p>
                Sprawdź jak wyglądają <br />
                przykładowe zadania i czy nie są dla Ciebie za trudne 😉.
              </p>
            </div>

            <div className={demo__wrapper__product}>
              <div className={demo__wrapper__product__img}>
                <img src={Spartan} alt="gladiator" />
              </div>
              <h3>Przykładowe zadania ze zbioru</h3>
              <p>
                Sprawdź swój poziom, zweryfikuj umiejętności i rozwijaj się
                dalej z moim zbiorem!
              </p>
              <a
                className={offer__wrapper__products__item__button}
                href="https://github.com/Przemocny/zbior-zadan-html-css-js-react"
                target="_blank"
              >
                Zerknij na zadania!
              </a>
            </div>
          </div>
        </AnimatedSection>
      </div>
    </React.Fragment>
  )
}

export default Demo
