import React, { useContext, Component } from "react"
import { AnimatedButton } from "@components/shared"

import { CartCtx, useContextState } from "@components/contexted"

import { offer__wrapper__products__item__button } from "../styles/offer.module.scss"

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const AddToCartButton = (props) => {
  const { product, onInit, modal, className, label } = props
  const { dispatch } = useContext(CartCtx)
  const { items, sum, unit } = useContextState(CartCtx, ["items"])

  const isInCart = items.filter((el) => el.slug === product.slug)
  let isInCartBool = isInCart.length > 0
  return (
    <AnimatedButton
      className={className}
      onClick={() => {
        console.log("dodaje produkt", product)
        modal.current.toggle()
        dispatch({
          type: "removeFromCart",
          payload: { product_id: 374 },
        })

        if (isInCartBool) {
          console.log("Produkt juz jest w koszyku")
        } else {
          dispatch({
            type: "addToCart",
            payload: product,
          })
        }
        onInit()
      }}
    >
      {label ? (
        <text>Dołączam do gladiatorów!</text>
      ) : (
        <text>Kliknij aby zamówić!</text>
      )}
    </AnimatedButton>
  )
}
class AddHomeProduct extends Component {
  state = {
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  }
  onInit = () => {
    if (this.props.salePrice) {
      this.setState({
        product_id: this.props.productId,
        // variation_id:this.props.variationId,
        name: this.props.name,
        slug: this.props.slug,
        sale: true,
        price: cleanPrice(this.props.salePrice),
        regularPrice: cleanPrice(this.props.regularPrice),
        regularValue: cleanPrice(this.props.regularPrice),
        value: cleanPrice(this.props.salePrice),
        quantity: 1,
        image: this.props.image,
      })
    } else {
      this.setState({
        product_id: this.props.productId,
        // variation_id:this.props.variationId,
        name: this.props.name,
        slug: this.props.slug,
        price: cleanPrice(this.props.regularPrice),
        regularPrice: cleanPrice(this.props.regularPrice),
        regularValue: cleanPrice(this.props.regularPrice),
        value: cleanPrice(this.props.regularPrice),
        quantity: 1,
        image: this.props.image,
      })
    }
  }

  componentDidMount() {
    this.onInit()
  }
  render() {
    return (
      <AddToCartButton
        product={this.state}
        onInit={this.onInit}
        modal={this.props.modal}
        className={this.props.className}
        label={this.props.label}
      />
    )
  }
}

export default AddHomeProduct
