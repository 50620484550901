import { useIntersectionObserver } from "@components/logic"
import React from "react"
import { useSpring, animated } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import animationConfig, { getAnimatedSvgProps } from "./animationConfig"
import { IAnimatedIcon } from "./icon"

const Problem1: React.FC = (): JSX.Element => {
  const { offset, pathRef } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()

  return (
    <div>
      <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={marktoObserve}
      >
        {typeof offset === "number" && inView ? (
          <AnimatedIcon offset={offset} />
        ) : (
          <>
            <g clipPath="url(#clip0)">
              <path
                d="M73.9991 55.5008C73.4469 55.5008 72.9992 55.9485 72.9992 56.5007V58.0866L70.9992 60.0866V56.5007C70.9963 53.741 68.7595 51.5043 65.9993 51.5009H53.9996C51.2399 51.5043 49.0031 53.741 48.9997 56.5007V60.5006H40.9999V56.5007C40.997 53.741 38.7602 51.5043 36 51.5009H24.0003C21.2406 51.5043 19.0038 53.741 19.0004 56.5007V60.0866L17.0004 58.0866V56.5007C17.0004 55.9485 16.5527 55.5008 16.0005 55.5008C15.4482 55.5008 15.0005 55.9485 15.0005 56.5007V58.5007C15.001 58.7658 15.106 59.0202 15.2935 59.2077L19.0004 62.9146V66.5005C19.0038 69.2607 21.2406 71.497 24.0003 71.5004H36C38.7602 71.497 40.997 69.2607 40.9999 66.5005V62.5006H48.9997V66.5005C49.0031 69.2607 51.2399 71.497 53.9996 71.5004H65.9993C68.7595 71.497 70.9963 69.2607 70.9992 66.5005V62.9146L74.7062 59.2077C74.8937 59.0202 74.9991 58.7658 74.9991 58.5007V56.5007C74.9991 55.9485 74.5514 55.5008 73.9991 55.5008ZM38.9999 66.5005C38.9999 68.1572 37.6572 69.5004 36 69.5004H24.0003C22.3436 69.5004 21.0004 68.1572 21.0004 66.5005V56.5007C21.0004 54.844 22.3436 53.5008 24.0003 53.5008H36C37.6572 53.5008 38.9999 54.844 38.9999 56.5007V66.5005ZM68.9993 66.5005C68.9993 68.1572 67.6565 69.5004 65.9993 69.5004H53.9996C52.3429 69.5004 50.9997 68.1572 50.9997 66.5005V56.5007C50.9997 54.844 52.3429 53.5008 53.9996 53.5008H65.9993C67.6565 53.5008 68.9993 54.844 68.9993 56.5007V66.5005Z"
                fill="#1B2029"
                ref={pathRef}
              />
            </g>
            <defs>
              <clipPath>
                <rect
                  width="60"
                  height="60"
                  fill="white"
                  transform="translate(15 15)"
                />
              </clipPath>
            </defs>
          </>
        )}
      </svg>
    </div>
  )
}

export default Problem1

const AnimatedIcon: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const { bgColor, fillColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(animationConfig.bg)
      await next(animationConfig.draw)
      await next(animationConfig.fill)
    },
    from: {
      x: offset,
      fillColor: animationConfig.bg.bgColor,
      bgColor: animationConfig.white,
    },
  })
  return (
    <>
      <animated.circle cx="45" cy="45" r="45" fill={bgColor} />
      <g clipPath="url(#clip0)">
        <animated.path
          d="M33 66.5005C33 67.0527 32.5522 67.5005 32 67.5005C31.4478 67.5005 31 67.0527 31 66.5005C31 65.9482 31.4478 65.5005 32 65.5005C32.5522 65.5005 33 65.9482 33 66.5005Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M31.0005 56.5007C31.0005 57.053 30.5527 57.5007 30.0005 57.5007C29.4482 57.5007 29.0005 57.053 29.0005 56.5007C29.0005 55.9485 29.4482 55.5007 30.0005 55.5007C30.5527 55.5007 31.0005 55.9485 31.0005 56.5007Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M28.0004 65.5005H25.0005V56.5007C25.0005 55.9485 24.5527 55.5007 24.0005 55.5007C23.4483 55.5007 23.0005 55.9485 23.0005 56.5007V66.5005C22.9981 66.7666 23.1026 67.0224 23.2906 67.2104C23.4785 67.3989 23.7349 67.5034 24.0005 67.5004H28.0004C28.5526 67.5004 29.0004 67.0527 29.0004 66.5005C29.0004 65.9482 28.5526 65.5005 28.0004 65.5005Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M35.9999 55.5007H34C33.4477 55.5007 33 55.9484 33 56.5006C33 57.0529 33.4477 57.5006 34 57.5006H35V64.5004C35 65.0527 35.4477 65.5004 35.9999 65.5004C36.5522 65.5004 36.9999 65.0527 36.9999 64.5004V56.5006C37.0028 56.2345 36.8984 55.9787 36.7104 55.7907C36.5219 55.6027 36.266 55.4982 35.9999 55.5007Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M65.9995 55.5007H63.9995C63.4473 55.5007 62.9995 55.9484 62.9995 56.5006C62.9995 57.0529 63.4473 57.5006 63.9995 57.5006H64.9995V64.5004C64.9995 65.0527 65.4472 65.5004 65.9995 65.5004C66.5517 65.5004 66.9994 65.0527 66.9994 64.5004V56.5006C67.0024 56.2345 66.8979 55.9787 66.7099 55.7907C66.5214 55.6027 66.2656 55.4982 65.9995 55.5007Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M57.9994 65.5005H54.9995V56.5007C54.9995 55.9485 54.5517 55.5007 53.9995 55.5007C53.4473 55.5007 52.9995 55.9485 52.9995 56.5007V66.5005C52.999 66.7661 53.103 67.0215 53.2895 67.2104C53.478 67.3984 53.7339 67.5029 53.9995 67.5005H57.9994C58.5516 67.5005 58.9994 67.0527 58.9994 66.5005C58.9994 65.9483 58.5516 65.5005 57.9994 65.5005Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M62.9995 66.5005C62.9995 67.0527 62.5518 67.5005 61.9995 67.5005C61.4473 67.5005 60.9995 67.0527 60.9995 66.5005C60.9995 65.9482 61.4473 65.5005 61.9995 65.5005C62.5518 65.5005 62.9995 65.9482 62.9995 66.5005Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M60.9995 56.5007C60.9995 57.053 60.5518 57.5007 59.9995 57.5007C59.4473 57.5007 58.9995 57.053 58.9995 56.5007C58.9995 55.9485 59.4473 55.5007 59.9995 55.5007C60.5518 55.5007 60.9995 55.9485 60.9995 56.5007Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M73.9991 55.5008C73.4469 55.5008 72.9992 55.9485 72.9992 56.5007V58.0866L70.9992 60.0866V56.5007C70.9963 53.741 68.7595 51.5043 65.9993 51.5009H53.9996C51.2399 51.5043 49.0031 53.741 48.9997 56.5007V60.5006H40.9999V56.5007C40.997 53.741 38.7602 51.5043 36 51.5009H24.0003C21.2406 51.5043 19.0038 53.741 19.0004 56.5007V60.0866L17.0004 58.0866V56.5007C17.0004 55.9485 16.5527 55.5008 16.0005 55.5008C15.4482 55.5008 15.0005 55.9485 15.0005 56.5007V58.5007C15.001 58.7658 15.106 59.0202 15.2935 59.2077L19.0004 62.9146V66.5005C19.0038 69.2607 21.2406 71.497 24.0003 71.5004H36C38.7602 71.497 40.997 69.2607 40.9999 66.5005V62.5006H48.9997V66.5005C49.0031 69.2607 51.2399 71.497 53.9996 71.5004H65.9993C68.7595 71.497 70.9963 69.2607 70.9992 66.5005V62.9146L74.7062 59.2077C74.8937 59.0202 74.9991 58.7658 74.9991 58.5007V56.5007C74.9991 55.9485 74.5514 55.5008 73.9991 55.5008ZM38.9999 66.5005C38.9999 68.1572 37.6572 69.5004 36 69.5004H24.0003C22.3436 69.5004 21.0004 68.1572 21.0004 66.5005V56.5007C21.0004 54.844 22.3436 53.5008 24.0003 53.5008H36C37.6572 53.5008 38.9999 54.844 38.9999 56.5007V66.5005ZM68.9993 66.5005C68.9993 68.1572 67.6565 69.5004 65.9993 69.5004H53.9996C52.3429 69.5004 50.9997 68.1572 50.9997 66.5005V56.5007C50.9997 54.844 52.3429 53.5008 53.9996 53.5008H65.9993C67.6565 53.5008 68.9993 54.844 68.9993 56.5007V66.5005Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M15.5176 36.3772L24.0003 41.0572V47.5009C24.0003 48.0532 24.448 48.5009 25.0003 48.5009H37.4912L44.5169 52.3768C44.8177 52.5428 45.1824 52.5428 45.4832 52.3768L52.5089 48.5009H64.9994C65.5516 48.5009 65.9993 48.0532 65.9993 47.5009V41.0572L69.9992 38.8512V43.168L67.1995 46.9008C66.9725 47.2041 66.9358 47.6093 67.1053 47.9482C67.2747 48.287 67.6209 48.5009 67.9993 48.5009H73.9992C74.378 48.5009 74.7242 48.287 74.8937 47.9482C75.0631 47.6093 75.0265 47.2041 74.7994 46.9008L71.9992 43.168V37.7472L74.4825 36.3772C74.8019 36.2014 75.0001 35.8659 75.0001 35.5012C75.0001 35.1369 74.8019 34.801 74.4825 34.6252L45.4832 18.6256C45.1824 18.4601 44.8177 18.4601 44.5169 18.6256L15.5176 34.6252C15.1982 34.801 15 35.1369 15 35.5012C15 35.8659 15.1982 36.2014 15.5176 36.3772ZM26.0002 46.5009V42.1612L33.8663 46.5009H26.0002ZM63.9994 46.5009H56.1338L63.9994 42.1612V46.5009ZM69.9992 46.5009L70.9992 45.168L71.9992 46.5009H69.9992ZM44.9998 20.6446L71.9294 35.5012L44.9998 50.3578L18.0707 35.5012L44.9998 20.6446Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M50.0596 28.0013C50.208 28.0883 50.3775 28.1337 50.5498 28.1312C50.9136 28.1307 51.2476 27.931 51.4199 27.6102C51.5488 27.3793 51.5816 27.1064 51.5098 26.8515C51.4331 26.5971 51.2612 26.3818 51.0298 26.2514L47.5299 24.3208C47.5113 24.3134 47.4942 24.3037 47.4786 24.2915L45.4787 23.1914C45.1789 23.0327 44.82 23.0327 44.5197 23.1914L41.3098 24.9614C41.073 25.0854 40.8987 25.3032 40.8298 25.5615C40.76 25.8154 40.7888 26.0864 40.9099 26.3207C41.1877 26.7968 41.7922 26.9696 42.28 26.7113L44.9997 25.2114L50.0596 28.0013Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M58.8187 32.8416L63.6487 35.5012L62.8987 35.9113C62.4148 36.1779 62.2361 36.7849 62.4983 37.2712C62.677 37.59 63.0129 37.7883 63.3786 37.7912C63.5456 37.7917 63.7102 37.7507 63.8576 37.6711L66.1984 36.3713C66.5182 36.1985 66.7174 35.8645 66.7184 35.5012C66.7155 35.136 66.5172 34.8 66.1984 34.6213L59.7884 31.0814C59.3016 30.827 58.7005 31.0037 58.4285 31.4813C58.2992 31.7132 58.2669 31.9866 58.3387 32.2425C58.4149 32.4969 58.5867 32.7117 58.8187 32.8416Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M45.8702 47.4512C46.0377 47.4521 46.2027 47.4106 46.3502 47.3311L55.1093 42.5011C55.3388 42.3707 55.5077 42.1554 55.58 41.901C55.66 41.6481 55.6307 41.3732 55.4999 41.1422C55.224 40.6701 54.6278 40.4948 54.14 40.7423L45.3805 45.5821C44.9015 45.8531 44.7282 46.4575 44.9904 46.9409C45.1715 47.2563 45.5069 47.4507 45.8702 47.4512Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M33.9898 30.9515C34.0689 30.6976 34.0401 30.4222 33.9098 30.1903C33.6368 29.7147 33.0372 29.5384 32.5499 29.7904L23.8001 34.6213C23.5653 34.7483 23.3919 34.9646 23.3192 35.2214C23.2401 35.4743 23.2689 35.7492 23.3993 35.9802C23.576 36.3014 23.9134 36.5007 24.2801 36.5011C24.4491 36.5031 24.6151 36.4582 24.7601 36.3713L33.5196 31.5516C33.7491 31.4207 33.9181 31.2054 33.9898 30.9515Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M37.6003 41.7012L28.8407 36.8712C28.3524 36.6237 27.7563 36.799 27.4794 37.2711C27.3515 37.5021 27.3207 37.7741 27.394 38.0275C27.4672 38.2809 27.6391 38.4947 27.8705 38.6212L36.6306 43.4512C36.7751 43.5381 36.9406 43.583 37.1096 43.5811C37.4758 43.5811 37.8132 43.3818 37.9904 43.061C38.1208 42.8296 38.1496 42.5542 38.0695 42.3013C37.9982 42.0469 37.8298 41.8316 37.6003 41.7012Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M38 28.5013C38 29.0536 37.5522 29.5013 37 29.5013C36.4478 29.5013 36 29.0536 36 28.5013C36 27.9491 36.4478 27.5013 37 27.5013C37.5522 27.5013 38 27.9491 38 28.5013Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M55.9995 29.5013C55.9995 30.0536 55.5518 30.5013 54.9995 30.5013C54.4473 30.5013 53.9995 30.0536 53.9995 29.5013C53.9995 28.9491 54.4473 28.5013 54.9995 28.5013C55.5518 28.5013 55.9995 28.9491 55.9995 29.5013Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M58.9995 39.5011C58.9995 40.0533 58.5518 40.5011 57.9995 40.5011C57.4473 40.5011 56.9995 40.0533 56.9995 39.5011C56.9995 38.9489 57.4473 38.5011 57.9995 38.5011C58.5518 38.5011 58.9995 38.9489 58.9995 39.5011Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
        <animated.path
          d="M42 44.501C42 45.0532 41.5522 45.501 41 45.501C40.4478 45.501 40 45.0532 40 44.501C40 43.9487 40.4478 43.501 41 43.501C41.5522 43.501 42 43.9487 42 44.501Z"
          {...getAnimatedSvgProps(fillColor, x, offset)}
        />
      </g>
    </>
  )
}
