import { useIntersectionObserver } from "@components/logic"
import React from "react"
import { useSpring, animated } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import { contactAnimationConfig } from "./animationConfig"
import { IAnimatedIcon } from "./icon"

const Contact: React.FC = (): JSX.Element => {
  const { offset, pathRef } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()

  return (
    <svg
      width="400"
      height="227"
      viewBox="0 0 400 227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      <path
        d="M362.211 34.2049C362.15 41.1677 361.64 48.1193 360.684 55.0164C360.651 55.2586 360.614 55.5008 360.581 55.743L295.384 55.3787C295.193 55.1381 295.004 54.8947 294.822 54.6483C294.213 53.8331 293.643 52.9946 293.119 52.1272C290.602 47.9485 289.45 43.0562 290.604 39.6808L290.625 39.6283C290.874 38.8892 291.267 38.2065 291.781 37.6196C294.414 34.6555 299.737 35.1496 304.568 37.5422C300.235 32.7985 296.785 26.8127 296.523 21.4697C296.266 16.2581 298.99 12.5352 301.946 9.37129C302.043 9.2667 302.14 9.16502 302.237 9.06335C302.284 9.01105 302.333 8.96166 302.38 8.90936C304.686 6.49826 307.374 4.12142 311.265 4.40894C315.532 4.72483 320.263 8.29962 323.581 12.4652C326.898 16.6279 329.102 21.3568 331.337 25.9719C333.574 30.5841 335.994 35.3142 339.585 39.2565C334.722 33.1051 330.801 26.1062 329.353 19.2992C327.905 12.4923 329.18 5.95466 333.513 2.74885C334.829 1.8056 336.351 1.19122 337.953 0.957137C338.14 0.92606 338.33 0.90083 338.523 0.878543C342.535 0.422015 347.325 1.80971 351.4 5.12676C355.888 8.77908 359 14.1935 360.548 19.3334C362.096 24.4733 362.261 29.4215 362.211 34.2049Z"
        fill="#F2F2F2"
      />
      <path
        d="M107.667 100.189C107.606 107.152 107.096 114.104 106.14 121.001C106.107 121.243 106.07 121.485 106.037 121.727L40.8399 121.363C40.6485 121.122 40.46 120.879 40.2775 120.633C39.6688 119.817 39.0983 118.979 38.5746 118.112C36.0575 113.933 34.9051 109.041 36.0599 105.665L36.0806 105.613C36.3298 104.874 36.7226 104.191 37.2364 103.604C39.8693 100.64 45.1928 101.134 50.0237 103.527C45.6907 98.7828 42.2405 92.7971 41.9783 87.454C41.7213 82.2425 44.446 78.5195 47.4012 75.3557C47.4982 75.2511 47.5951 75.1494 47.6921 75.0477C47.7391 74.9954 47.789 74.946 47.8359 74.8937C50.1417 72.4826 52.8298 70.1058 56.7206 70.3933C60.9878 70.7092 65.7189 74.284 69.0362 78.4496C72.3534 82.6123 74.5578 87.3411 76.7922 91.9563C79.0295 96.5685 81.45 101.299 85.0401 105.241C80.1776 99.0895 76.2571 92.0906 74.8088 85.2836C73.3606 78.4767 74.6352 71.939 78.9688 68.7332C80.2845 67.79 81.8069 67.1756 83.4087 66.9415C83.5956 66.9104 83.7855 66.8852 83.9784 66.8629C87.9902 66.4064 92.7802 67.7941 96.8555 71.1111C101.344 74.7635 104.455 80.1779 106.004 85.3178C107.552 90.4577 107.717 95.4059 107.667 100.189Z"
        fill="#F2F2F2"
      />
      <path
        d="M139.921 149.522C139.302 149.523 138.694 149.357 138.161 149.042C137.628 148.727 137.19 148.274 136.892 147.731L133.402 141.357C132.964 140.555 132.862 139.612 133.119 138.734C133.375 137.857 133.969 137.118 134.77 136.678L187.892 107.584C188.694 107.146 189.637 107.044 190.514 107.301C191.391 107.557 192.131 108.151 192.571 108.952L196.061 115.327C196.499 116.129 196.601 117.072 196.344 117.949C196.088 118.826 195.494 119.566 194.693 120.006L141.571 149.099C141.065 149.377 140.498 149.522 139.921 149.522Z"
        fill="#F8DF2A"
      />
      <path
        d="M213.839 115.816C213.48 115.816 213.124 115.76 212.783 115.651C212.351 115.514 211.951 115.293 211.605 115.001C211.259 114.709 210.975 114.351 210.769 113.948L207.439 107.489C207.021 106.676 206.942 105.731 207.22 104.86C207.499 103.99 208.111 103.265 208.922 102.845L262.755 75.087C263.567 74.6691 264.512 74.5907 265.383 74.8689C266.253 75.1472 266.978 75.7594 267.397 76.5712L270.727 83.0309C271.145 83.8436 271.223 84.7889 270.945 85.6594C270.667 86.5299 270.055 87.2544 269.243 87.674L215.411 115.432C214.925 115.684 214.386 115.816 213.839 115.816Z"
        fill="#F8DF2A"
      />
      <path
        d="M212.868 117.378C212.228 117.378 211.6 117.2 211.055 116.864C210.51 116.528 210.068 116.047 209.781 115.475L206.521 108.98C206.152 108.247 206.058 107.407 206.254 106.611C206.275 106.523 206.3 106.435 206.329 106.348C206.395 106.15 206.467 105.84 206.55 105.482C206.814 104.339 207.142 102.917 207.813 102.581L231.466 88.242C231.871 88.0356 232.313 87.9114 232.765 87.8764C233.218 87.8415 233.674 87.8964 234.105 88.0381C234.537 88.1798 234.936 88.4055 235.28 88.7021C235.624 88.9988 235.906 89.3605 236.11 89.7666L242.076 102.067C242.484 102.884 242.552 103.829 242.264 104.696C241.977 105.563 241.357 106.28 240.541 106.691L214.387 117.018C213.916 117.255 213.396 117.379 212.868 117.378Z"
        fill="#2F2E41"
      />
      <path
        d="M202.064 84.5145C216.617 84.5145 228.414 72.7154 228.414 58.1606C228.414 43.6057 216.617 31.8066 202.064 31.8066C187.511 31.8066 175.714 43.6057 175.714 58.1606C175.714 72.7154 187.511 84.5145 202.064 84.5145Z"
        fill="#F8DF2A"
      />
      <path
        d="M197.912 225.508H190.646C189.732 225.507 188.856 225.144 188.21 224.498C187.564 223.851 187.2 222.975 187.199 222.061V161.487C187.2 160.573 187.564 159.697 188.21 159.051C188.856 158.405 189.732 158.041 190.646 158.04H197.912C198.826 158.041 199.702 158.405 200.348 159.051C200.994 159.697 201.358 160.573 201.359 161.487V222.061C201.358 222.975 200.994 223.851 200.348 224.498C199.702 225.144 198.826 225.507 197.912 225.508Z"
        fill="#F8DF2A"
      />
      <path
        d="M229.222 197.841C228.673 197.842 228.132 197.712 227.644 197.462C227.155 197.211 226.734 196.847 226.414 196.401L191.187 147.126C190.657 146.382 190.443 145.458 190.593 144.556C190.742 143.655 191.244 142.849 191.986 142.317L197.898 138.09C198.642 137.559 199.566 137.345 200.467 137.495C201.368 137.645 202.174 138.146 202.706 138.889L237.933 188.163C238.463 188.907 238.677 189.832 238.527 190.733C238.377 191.635 237.876 192.44 237.134 192.972L231.222 197.2C230.639 197.618 229.94 197.842 229.222 197.841Z"
        fill="#F8DF2A"
      />
      <path
        d="M219.877 225.578C219.152 225.565 218.44 225.387 217.795 225.057L211.29 221.818C209.589 220.97 208.652 219.384 209.201 218.281L225.967 184.6C226.516 183.497 228.346 183.289 230.047 184.136L236.553 187.375C237.311 187.735 237.956 188.296 238.417 188.996C238.618 189.269 238.744 189.588 238.783 189.924C238.823 190.26 238.774 190.6 238.642 190.911L221.876 224.593C221.555 225.239 220.794 225.578 219.877 225.578Z"
        fill="#F8DF2A"
      />
      <path
        d="M177.558 131.58C176.904 131.579 176.264 131.392 175.713 131.042C175.161 130.691 174.72 130.192 174.44 129.601L168.455 117.317C168.262 116.907 168.151 116.464 168.129 116.011C168.107 115.559 168.175 115.107 168.328 114.68C168.482 114.254 168.717 113.862 169.022 113.527C169.327 113.192 169.694 112.92 170.104 112.728L196.024 102.961C196.685 102.649 198.006 103.269 199.067 103.767C199.401 103.923 199.688 104.058 199.885 104.128C199.971 104.159 200.056 104.194 200.139 104.231C200.886 104.569 201.488 105.162 201.836 105.904L204.931 112.48C205.319 113.307 205.363 114.255 205.054 115.115C204.744 115.975 204.106 116.677 203.28 117.067L179.045 131.242C178.581 131.463 178.073 131.579 177.558 131.58Z"
        fill="#2F2E41"
      />
      <path
        d="M198.556 67.9284L198.529 66.9436C200.361 66.8926 201.976 66.7777 203.19 65.891C203.534 65.6253 203.816 65.2886 204.019 64.9038C204.221 64.519 204.338 64.0952 204.362 63.6611C204.383 63.3959 204.343 63.1293 204.245 62.8821C204.146 62.6349 203.992 62.4137 203.794 62.2358C202.989 61.5554 201.693 61.7756 200.748 62.2089L199.933 62.5825L201.495 51.1631L202.471 51.2968L201.142 61.0105C202.426 60.633 203.615 60.7956 204.43 61.4837C204.743 61.7569 204.988 62.0986 205.147 62.4824C205.306 62.8661 205.374 63.2814 205.346 63.6957C205.317 64.279 205.161 64.8489 204.889 65.3655C204.617 65.8821 204.235 66.3329 203.77 66.6863C202.211 67.826 200.185 67.8828 198.556 67.9284Z"
        fill="#2F2E41"
      />
      <path
        d="M212.599 52.1348H207.296V53.1196H212.599V52.1348Z"
        fill="#2F2E41"
      />
      <path
        d="M195.859 52.1348H190.556V53.1196H195.859V52.1348Z"
        fill="#2F2E41"
      />
      <path
        d="M244.296 168.889H162.329C161.767 168.891 161.214 168.745 160.726 168.464C160.238 168.184 159.833 167.78 159.552 167.292C159.271 166.805 159.123 166.252 159.124 165.689C159.125 165.127 159.275 164.574 159.558 164.088L200.541 93.0926C200.82 92.6038 201.223 92.1979 201.71 91.9166C202.197 91.6354 202.75 91.4889 203.313 91.4922C203.875 91.4889 204.428 91.6354 204.915 91.9166C205.403 92.1979 205.806 92.6038 206.084 93.0926L247.068 164.088C247.349 164.575 247.496 165.127 247.496 165.689C247.496 166.25 247.349 166.802 247.068 167.289C246.787 167.775 246.383 168.179 245.896 168.46C245.41 168.741 244.858 168.889 244.296 168.889Z"
        fill="#2F2E41"
      />
      <path
        d="M204.055 31.4832C201.133 19.7689 189.807 10.7744 177.671 10.9982C171.663 11.1668 165.869 13.268 161.149 16.99C158.769 18.8422 156.721 21.084 155.09 23.6202C153.261 26.4753 152.112 29.6982 151.215 32.9511C149.404 39.5238 148.55 46.9614 143.952 52.3289C142.045 54.5017 139.695 56.2399 137.059 57.4259C134.423 58.6119 131.563 59.2182 128.673 59.2037C125.761 59.1583 122.899 58.4393 120.311 57.1029C117.723 55.7664 115.48 53.8489 113.757 51.5006C110.296 46.8858 108.592 40.5035 110.591 34.9339C111.573 32.2009 113.682 29.6192 116.7 29.1635C118.076 28.9772 119.476 29.1975 120.729 29.7971C121.981 30.3967 123.031 31.3496 123.749 32.5383C125.106 34.9197 124.672 38.0972 122.972 40.1977C121.182 42.41 118.189 43.0226 115.55 42.1338C114.31 41.712 113.219 40.9381 112.411 39.9064C111.821 39.161 110.775 40.206 111.359 40.9438C112.295 42.0666 113.504 42.9302 114.869 43.452C116.235 43.9737 117.711 44.1361 119.157 43.9236C120.6 43.7053 121.956 43.0948 123.076 42.1588C124.196 41.2228 125.037 39.9971 125.509 38.6156C126.025 37.2458 126.161 35.7614 125.901 34.3207C125.641 32.8799 124.995 31.5367 124.032 30.434C123.029 29.3428 121.762 28.5274 120.354 28.0659C118.945 27.6044 117.442 27.5122 115.987 27.798C112.872 28.4578 110.609 31.0206 109.435 33.8605C108.278 36.8108 107.973 40.0269 108.554 43.1423C109.09 46.1485 110.254 49.0081 111.969 51.5348C113.683 54.0615 115.911 56.1986 118.507 57.8071C123.759 61.0328 130.315 61.4766 136.078 59.4046C138.957 58.3859 141.587 56.7664 143.792 54.6536C146.162 52.2488 147.976 49.3534 149.106 46.1715C151.704 39.3681 152.085 31.7551 155.761 25.3601C158.967 19.93 164.025 15.8394 170.005 13.8396C175.95 11.7853 182.457 12.0977 188.178 14.7121C193.867 17.347 198.449 21.8971 201.124 27.5675C201.761 28.9491 202.265 30.3884 202.629 31.8657C202.859 32.7898 204.286 32.4064 204.055 31.4832Z"
        fill="#2F2E41"
      />

      {typeof offset === "number" && inView ? (
        <AnimatedIcon offset={offset} />
      ) : (
        <path
          d="M385.315 124.326H269.613C267.394 124.323 265.267 123.44 263.697 121.871C262.128 120.302 261.246 118.174 261.243 115.954V63.7579C261.246 61.5385 262.128 59.4107 263.697 57.8413C265.267 56.272 267.394 55.3892 269.613 55.3867H385.315C387.534 55.3892 389.662 56.272 391.231 57.8413C392.8 59.4107 393.683 61.5385 393.685 63.7579V115.954C393.683 118.174 392.8 120.302 391.231 121.871C389.662 123.44 387.534 124.323 385.315 124.326ZM269.613 56.3716C267.655 56.3738 265.778 57.1527 264.393 58.5374C263.009 59.9221 262.23 61.7996 262.228 63.7579V115.954C262.23 117.913 263.009 119.79 264.393 121.175C265.778 122.56 267.655 123.338 269.613 123.341H385.315C387.273 123.338 389.15 122.56 390.535 121.175C391.919 119.79 392.698 117.913 392.7 115.954V63.7579C392.698 61.7996 391.919 59.9221 390.535 58.5374C389.15 57.1527 387.273 56.3738 385.315 56.3716H269.613Z"
          ref={pathRef}
          fill="#3F3D56"
        />
      )}
    </svg>
  )
}

export default Contact

const AnimatedIcon: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const { x, featherColor, lineColor, innerLines }: any = useSpring({
    to: async (next: any) => {
      await next(contactAnimationConfig.drawLine)
      await next(contactAnimationConfig.fillColor)
      await next(contactAnimationConfig.fillLinesColor)
      await next(contactAnimationConfig.fillFeather)
    },
    from: {
      x: offset,
      lineColor: contactAnimationConfig.white,
      innerLines: contactAnimationConfig.white,
      featherColor: contactAnimationConfig.white,
    },
  })

  return (
    <>
      <animated.path
        d="M362.211 34.2049C362.15 41.1677 361.64 48.1193 360.684 55.0164C360.651 55.2586 360.614 55.5008 360.581 55.743L295.384 55.3787C295.193 55.1381 295.004 54.8947 294.822 54.6483C294.213 53.8331 293.643 52.9946 293.119 52.1272C290.602 47.9485 289.45 43.0562 290.604 39.6808L290.625 39.6283C290.874 38.8892 291.267 38.2065 291.781 37.6196C294.414 34.6555 299.737 35.1496 304.568 37.5422C300.235 32.7985 296.785 26.8127 296.523 21.4697C296.266 16.2581 298.99 12.5352 301.946 9.37129C302.043 9.2667 302.14 9.16502 302.237 9.06335C302.284 9.01105 302.333 8.96166 302.38 8.90936C304.686 6.49826 307.374 4.12142 311.265 4.40894C315.532 4.72483 320.263 8.29962 323.581 12.4652C326.898 16.6279 329.102 21.3568 331.337 25.9719C333.574 30.5841 335.994 35.3142 339.585 39.2565C334.722 33.1051 330.801 26.1062 329.353 19.2992C327.905 12.4923 329.18 5.95466 333.513 2.74885C334.829 1.8056 336.351 1.19122 337.953 0.957137C338.14 0.92606 338.33 0.90083 338.523 0.878543C342.535 0.422015 347.325 1.80971 351.4 5.12676C355.888 8.77908 359 14.1935 360.548 19.3334C362.096 24.4733 362.261 29.4215 362.211 34.2049Z"
        fill={featherColor}
      />
      <animated.path
        d="M107.667 100.189C107.606 107.152 107.096 114.104 106.14 121.001C106.107 121.243 106.07 121.485 106.037 121.727L40.8399 121.363C40.6485 121.122 40.46 120.879 40.2775 120.633C39.6688 119.817 39.0983 118.979 38.5746 118.112C36.0575 113.933 34.9051 109.041 36.0599 105.665L36.0806 105.613C36.3298 104.874 36.7226 104.191 37.2364 103.604C39.8693 100.64 45.1928 101.134 50.0237 103.527C45.6907 98.7828 42.2405 92.7971 41.9783 87.454C41.7213 82.2425 44.446 78.5195 47.4012 75.3557C47.4982 75.2511 47.5951 75.1494 47.6921 75.0477C47.7391 74.9954 47.789 74.946 47.8359 74.8937C50.1417 72.4826 52.8298 70.1058 56.7206 70.3933C60.9878 70.7092 65.7189 74.284 69.0362 78.4496C72.3534 82.6123 74.5578 87.3411 76.7922 91.9563C79.0295 96.5685 81.45 101.299 85.0401 105.241C80.1776 99.0895 76.2571 92.0906 74.8088 85.2836C73.3606 78.4767 74.6352 71.939 78.9688 68.7332C80.2845 67.79 81.8069 67.1756 83.4087 66.9415C83.5956 66.9104 83.7855 66.8852 83.9784 66.8629C87.9902 66.4064 92.7802 67.7941 96.8555 71.1111C101.344 74.7635 104.455 80.1779 106.004 85.3178C107.552 90.4577 107.717 95.4059 107.667 100.189Z"
        fill={featherColor}
      />
      <path
        d="M65.011 121.498L63.7643 121.491C63.3685 121.243 62.9708 120.999 62.5712 120.757C62.3238 120.601 62.0736 120.451 61.8232 120.297C57.688 117.771 53.5268 115.349 49.3399 113.029C45.1567 110.707 40.9525 108.492 36.7273 106.384C36.6153 106.335 36.5239 106.249 36.469 106.14C36.4141 106.031 36.3992 105.906 36.4269 105.787C36.4314 105.771 36.4384 105.756 36.4477 105.743C36.5066 105.632 36.6383 105.586 36.8306 105.681C37.3488 105.941 37.8701 106.201 38.3883 106.467C42.6251 108.61 46.8436 110.861 51.0441 113.219C55.2416 115.576 59.4152 118.037 63.5648 120.602C63.6521 120.655 63.7395 120.711 63.8267 120.764C64.2224 121.009 64.6153 121.253 65.011 121.498Z"
        fill="white"
      />
      <path
        d="M81.0854 121.588L80.3145 121.583C80.1407 121.34 79.9698 121.097 79.7959 120.853C75.7108 115.124 71.6265 109.394 67.5431 103.665C60.856 94.285 54.1709 84.9051 47.4878 75.5252C47.4482 75.4747 47.4187 75.417 47.4011 75.3553C47.3439 75.1476 47.4964 75.0258 47.6919 75.0474C47.7744 75.0591 47.8533 75.0884 47.9236 75.1331C47.9938 75.1778 48.0537 75.2369 48.0993 75.3066C51.8484 80.5669 55.5966 85.8252 59.3438 91.0816C65.9961 100.415 72.6474 109.747 79.2978 119.078C79.7208 119.67 80.1438 120.265 80.5668 120.858C80.7407 121.101 80.9146 121.344 81.0854 121.588Z"
        fill="white"
      />
      <path
        d="M98.2597 117.11C98.2204 118.41 98.1344 119.688 98.0221 120.955C98.0004 121.197 97.9786 121.44 97.9567 121.682L97.1479 121.677C97.1726 121.435 97.1974 121.193 97.2191 120.951C97.39 119.12 97.5113 117.27 97.5278 115.368C97.5493 110.098 97.0357 104.838 95.9949 99.6712C94.8886 94.1076 93.3001 88.651 91.2474 83.363C89.093 77.7859 86.4955 72.3903 83.4798 67.2279C83.423 67.1438 83.3977 67.0423 83.4084 66.9413C83.4418 66.7108 83.7487 66.6424 83.9782 66.8627C84.0235 66.9059 84.0625 66.9553 84.0941 67.0094C84.4672 67.651 84.8335 68.2946 85.1931 68.9401C88.0975 74.1312 90.5836 79.5456 92.6275 85.1318C94.5744 90.4371 96.0554 95.902 97.0535 101.464C97.9885 106.625 98.3927 111.867 98.2597 117.11Z"
        fill="white"
      />
      <path
        d="M319.555 55.5136L318.309 55.5066C317.913 55.2591 317.515 55.0145 317.116 54.7728C316.868 54.6166 316.618 54.4663 316.368 54.313C312.232 51.787 308.071 49.3641 303.884 47.0443C299.701 44.7225 295.497 42.5077 291.272 40.3998C291.16 40.3511 291.068 40.2646 291.013 40.1554C290.959 40.0463 290.944 39.9214 290.971 39.8024C290.976 39.7868 290.983 39.772 290.992 39.7587C291.051 39.648 291.183 39.602 291.375 39.6966C291.893 39.9565 292.415 40.2164 292.933 40.4821C297.17 42.626 301.388 44.877 305.588 47.2349C309.786 49.5918 313.96 52.0527 318.109 54.6177C318.197 54.6708 318.284 54.7267 318.371 54.7798C318.767 55.0244 319.16 55.269 319.555 55.5136Z"
        fill="white"
      />
      <path
        d="M335.63 55.6032L334.859 55.5989C334.685 55.3556 334.514 55.1122 334.34 54.8689C330.255 49.1394 326.171 43.41 322.087 37.6806C315.4 28.3007 308.715 18.9207 302.032 9.54083C301.992 9.49034 301.963 9.43265 301.945 9.37095C301.888 9.16327 302.041 9.04146 302.236 9.063C302.319 9.07477 302.398 9.10399 302.468 9.1487C302.538 9.19341 302.598 9.25259 302.643 9.32228C306.393 14.5826 310.141 19.8409 313.888 25.0972C320.54 34.4303 327.192 43.7623 333.842 53.0933C334.265 53.6856 334.688 54.2808 335.111 54.8731C335.285 55.1165 335.459 55.3599 335.63 55.6032Z"
        fill="white"
      />
      <path
        d="M352.804 51.1259C352.765 52.4253 352.679 53.704 352.567 54.9708C352.545 55.2131 352.523 55.4554 352.501 55.6976L351.693 55.6931C351.717 55.4509 351.742 55.2086 351.764 54.9663C351.935 53.1361 352.056 51.2852 352.072 49.3841C352.094 44.1133 351.58 38.8539 350.54 33.6868C349.433 28.1233 347.845 22.6666 345.792 17.3787C343.638 11.8015 341.04 6.40592 338.024 1.24352C337.968 1.15938 337.942 1.05789 337.953 0.956933C337.986 0.726392 338.293 0.658028 338.523 0.878332C338.568 0.921556 338.607 0.970927 338.639 1.025C339.012 1.66667 339.378 2.31025 339.738 2.95574C342.642 8.1468 345.128 13.5612 347.172 19.1475C349.119 24.4527 350.6 29.9176 351.598 35.4801C352.533 40.6405 352.937 45.8831 352.804 51.1259Z"
        fill="white"
      />
      <animated.path
        d="M385.315 124.326H269.613C267.394 124.323 265.267 123.44 263.697 121.871C262.128 120.302 261.246 118.174 261.243 115.954V63.7579C261.246 61.5385 262.128 59.4107 263.697 57.8413C265.267 56.272 267.394 55.3892 269.613 55.3867H385.315C387.534 55.3892 389.662 56.272 391.231 57.8413C392.8 59.4107 393.683 61.5385 393.685 63.7579V115.954C393.683 118.174 392.8 120.302 391.231 121.871C389.662 123.44 387.534 124.323 385.315 124.326ZM269.613 56.3716C267.655 56.3738 265.778 57.1527 264.393 58.5374C263.009 59.9221 262.23 61.7996 262.228 63.7579V115.954C262.23 117.913 263.009 119.79 264.393 121.175C265.778 122.56 267.655 123.338 269.613 123.341H385.315C387.273 123.338 389.15 122.56 390.535 121.175C391.919 119.79 392.698 117.913 392.7 115.954V63.7579C392.698 61.7996 391.919 59.9221 390.535 58.5374C389.15 57.1527 387.273 56.3738 385.315 56.3716H269.613Z"
        stroke="#3F3D56"
        strokeWidth={0.5}
        strokeDashoffset={x}
        strokeDasharray={offset}
        fill={lineColor}
      />
      <animated.path
        d="M292.941 90.7777C298.855 90.7777 303.649 85.983 303.649 80.0685C303.649 74.154 298.855 69.3594 292.941 69.3594C287.028 69.3594 282.234 74.154 282.234 80.0685C282.234 85.983 287.028 90.7777 292.941 90.7777Z"
        fill={innerLines}
      />
      <animated.path
        d="M316.194 72.929C315.726 72.9367 315.279 73.1282 314.951 73.4621C314.623 73.7959 314.439 74.2454 314.439 74.7137C314.439 75.182 314.623 75.6315 314.951 75.9654C315.279 76.2993 315.726 76.4907 316.194 76.4985H370.785C371.257 76.5051 371.713 76.3239 372.052 75.9948C372.391 75.6657 372.586 75.2155 372.594 74.7429C372.602 74.2704 372.422 73.814 372.093 73.474C371.765 73.1339 371.316 72.9379 370.843 72.929C370.824 72.9286 370.804 72.9286 370.785 72.929H316.194Z"
        fill={innerLines}
      />
      <animated.path
        d="M316.194 83.6379C315.726 83.6457 315.279 83.8372 314.951 84.171C314.623 84.5049 314.439 84.9544 314.439 85.4227C314.439 85.891 314.623 86.3405 314.951 86.6744C315.279 87.0082 315.726 87.1997 316.194 87.2075H370.785C371.257 87.214 371.713 87.0329 372.052 86.7038C372.391 86.3747 372.586 85.9245 372.594 85.4519C372.602 84.9794 372.422 84.523 372.093 84.1829C371.765 83.8429 371.316 83.6469 370.843 83.6379C370.824 83.6376 370.804 83.6376 370.785 83.6379H316.194Z"
        fill={innerLines}
      />
      <animated.path
        d="M283.892 96.0725C283.424 96.0803 282.977 96.2717 282.649 96.6056C282.321 96.9395 282.137 97.389 282.137 97.8573C282.137 98.3256 282.321 98.7751 282.649 99.109C282.977 99.4429 283.424 99.6343 283.892 99.6421H370.978C371.212 99.6465 371.446 99.6046 371.664 99.5188C371.883 99.4329 372.082 99.3048 372.251 99.1417C372.42 98.9786 372.555 98.7838 372.648 98.5685C372.742 98.3531 372.792 98.1214 372.796 97.8867C372.8 97.6519 372.757 97.4187 372.671 97.2004C372.584 96.9821 372.456 96.7829 372.292 96.6144C372.129 96.4459 371.934 96.3113 371.718 96.2183C371.503 96.1253 371.271 96.0758 371.036 96.0725C371.017 96.0722 370.997 96.0722 370.978 96.0725H283.892Z"
        fill={innerLines}
      />
      <animated.path
        d="M283.892 106.781C283.424 106.789 282.977 106.981 282.649 107.315C282.321 107.648 282.137 108.098 282.137 108.566C282.137 109.035 282.321 109.484 282.649 109.818C282.977 110.152 283.424 110.343 283.892 110.351H370.978C371.212 110.355 371.446 110.314 371.664 110.228C371.883 110.142 372.082 110.014 372.251 109.851C372.42 109.688 372.555 109.493 372.648 109.277C372.742 109.062 372.792 108.83 372.796 108.596C372.8 108.361 372.757 108.128 372.671 107.909C372.584 107.691 372.456 107.492 372.292 107.323C372.129 107.155 371.934 107.02 371.718 106.927C371.503 106.834 371.271 106.785 371.036 106.781C371.017 106.781 370.997 106.781 370.978 106.781H283.892Z"
        fill={innerLines}
      />
      <animated.path
        d="M130.771 190.309H15.0687C12.8496 190.306 10.7221 189.424 9.15298 187.854C7.58385 186.285 6.70123 184.157 6.69873 181.938V129.741C6.70123 127.522 7.58386 125.394 9.15299 123.825C10.7221 122.255 12.8496 121.373 15.0687 121.37H130.771C132.99 121.373 135.117 122.255 136.686 123.825C138.255 125.394 139.138 127.522 139.141 129.741V181.938C139.138 184.157 138.255 186.285 136.686 187.854C135.117 189.424 132.99 190.306 130.771 190.309ZM15.0687 122.355C13.1107 122.357 11.2335 123.136 9.84896 124.521C8.46444 125.906 7.68564 127.783 7.68343 129.741V181.938C7.68564 183.896 8.46444 185.774 9.84896 187.158C11.2335 188.543 13.1107 189.322 15.0687 189.324H130.771C132.729 189.322 134.606 188.543 135.99 187.158C137.375 185.774 138.154 183.896 138.156 181.938V129.741C138.154 127.783 137.375 125.906 135.99 124.521C134.606 123.136 132.729 122.357 130.771 122.355H15.0687Z"
        stroke="#3F3D56"
        strokeWidth={0.5}
        strokeDashoffset={x}
        strokeDasharray={offset}
        fill={lineColor}
      />
      <animated.path
        d="M29.3477 143.284C28.8795 143.292 28.4332 143.484 28.1048 143.818C27.7765 144.151 27.5925 144.601 27.5925 145.069C27.5925 145.537 27.7765 145.987 28.1048 146.321C28.4332 146.655 28.8795 146.846 29.3477 146.854H116.433C116.668 146.858 116.901 146.817 117.12 146.731C117.338 146.645 117.538 146.517 117.707 146.354C117.876 146.191 118.011 145.996 118.104 145.78C118.198 145.565 118.248 145.333 118.252 145.099C118.256 144.864 118.213 144.631 118.127 144.412C118.04 144.194 117.912 143.995 117.748 143.826C117.585 143.658 117.39 143.523 117.174 143.43C116.958 143.337 116.727 143.288 116.492 143.284C116.472 143.284 116.453 143.284 116.433 143.284H29.3477Z"
        fill={innerLines}
      />
      <animated.path
        d="M29.3477 153.993C28.8795 154.001 28.4332 154.193 28.1048 154.526C27.7765 154.86 27.5925 155.31 27.5925 155.778C27.5925 156.246 27.7765 156.696 28.1048 157.03C28.4332 157.364 28.8795 157.555 29.3477 157.563H116.433C116.668 157.567 116.901 157.526 117.12 157.44C117.338 157.354 117.538 157.226 117.707 157.063C117.876 156.9 118.011 156.705 118.104 156.489C118.198 156.274 118.248 156.042 118.252 155.808C118.256 155.573 118.213 155.34 118.127 155.121C118.04 154.903 117.912 154.704 117.748 154.535C117.585 154.367 117.39 154.232 117.174 154.139C116.958 154.046 116.727 153.997 116.492 153.993C116.472 153.993 116.453 153.993 116.433 153.993H29.3477Z"
        fill={innerLines}
      />
      <animated.path
        d="M29.3477 164.826C28.8795 164.834 28.4332 165.026 28.1048 165.359C27.7765 165.693 27.5925 166.143 27.5925 166.611C27.5925 167.079 27.7765 167.529 28.1048 167.863C28.4332 168.197 28.8795 168.388 29.3477 168.396H116.433C116.668 168.4 116.901 168.358 117.12 168.273C117.338 168.187 117.538 168.059 117.707 167.896C117.876 167.733 118.011 167.538 118.104 167.322C118.198 167.107 118.248 166.875 118.252 166.641C118.256 166.406 118.213 166.173 118.127 165.954C118.04 165.736 117.912 165.537 117.748 165.368C117.585 165.2 117.39 165.065 117.174 164.972C116.958 164.879 116.727 164.83 116.492 164.826C116.472 164.826 116.453 164.826 116.433 164.826H29.3477Z"
        fill={innerLines}
      />
      <animated.path
        d="M9.70718 177.32C22.651 177.541 22.649 196.798 9.70676 197.017C-3.23667 196.796 -3.23464 177.54 9.70718 177.32Z"
        fill="#F8DF2A"
      />
      <animated.path
        d="M9.70712 192.531C9.52365 192.531 9.34776 192.458 9.21803 192.328C9.08829 192.198 9.01532 192.022 9.0151 191.839V188.169C9.01502 188.087 8.98251 188.009 8.9247 187.951C8.86689 187.893 8.78849 187.861 8.70672 187.86H5.03758C4.85402 187.86 4.67797 187.788 4.54817 187.658C4.41837 187.528 4.34546 187.352 4.34546 187.168C4.34546 186.985 4.41837 186.809 4.54817 186.679C4.67797 186.549 4.85402 186.476 5.03758 186.476H8.70675C8.7885 186.476 8.86688 186.443 8.9247 186.386C8.98251 186.328 9.01503 186.249 9.01512 186.168V182.498C9.01561 182.315 9.08873 182.139 9.21845 182.01C9.34817 181.88 9.52389 181.808 9.7071 181.808C9.89031 181.808 10.066 181.88 10.1958 182.01C10.3255 182.139 10.3986 182.315 10.3991 182.498V186.168C10.3992 186.249 10.4317 186.328 10.4895 186.386C10.5473 186.443 10.6257 186.476 10.7075 186.476H14.3766C14.5602 186.476 14.7362 186.549 14.866 186.679C14.9958 186.809 15.0688 186.985 15.0688 187.168C15.0688 187.352 14.9958 187.528 14.866 187.658C14.7362 187.788 14.5602 187.86 14.3766 187.86H10.7075C10.6257 187.861 10.5473 187.893 10.4895 187.951C10.4317 188.009 10.3992 188.087 10.3991 188.169V191.839C10.3989 192.022 10.3259 192.198 10.1962 192.328C10.0665 192.458 9.89058 192.531 9.70712 192.531Z"
        fill="white"
      />
    </>
  )
}
