import React from "react"

import { Layout } from "@components/layout"
import {
  Reviews,
  Contact,
  Programmer,
  Problem,
  Predecessors,
  Meet,
  Community,
  Offer,
  Demo,
  Guarantee,
  Gladiators,
  Video,
  AboutTasks,
} from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  const { page, productsWithDetails } = pageContext

  return (
    <Layout {...page}>
      <Programmer />
      <Problem />
      <Predecessors />
      <Meet />
      <Community />
      <Reviews />
      <AboutTasks />
      <Offer allProducts={productsWithDetails} />
      <Video />
      <Demo />
      <Guarantee />
      <Gladiators />
      <Contact />
    </Layout>
  )
}

export default IndexPage
