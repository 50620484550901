export default {
  bg: {
    bgColor: "#F8DF2A",
    config: {
      duration: 400,
    },
  },
  draw: {
    x: 0,
    config: {
      duration: 800,
    },
  },
  fill: {
    fillColor: "#1B2029",
    config: {
      duration: 200,
    },
  },
  white: "#FFFFFF",
}

export const getAnimatedSvgProps = (
  fillColor: string,
  strokeDashoffset: number,
  strokeDasharray: number
) => {
  return {
    stroke: "#1B2029",
    strokeWidth: 0.3,
    strokeDashoffset,
    strokeDasharray,
    fill: fillColor,
  }
}

export const sectionSvgProps = (
  fillColor: string,
  strokeDashoffset: number,
  strokeDasharray: number
) => {
  return {
    stroke: "#2F2E41",
    strokeWidth: 0.3,
    strokeDashoffset,
    strokeDasharray,
    fill: fillColor,
  }
}

export const sittingSvgProps = (
  fillColor: string,
  strokeDashoffset: number,
  strokeDasharray: number
) => {
  return {
    stroke: "#CCCCCC",
    strokeWidth: 0.9,
    strokeDashoffset,
    strokeDasharray,
    fill: fillColor,
  }
}

export const contactAnimationConfig = {
  drawLine: {
    x: 0,
    config: {
      duration: 900,
    },
  },
  fillColor: {
    lineColor: "#3F3D56",
    config: {
      duration: 300,
    },
  },
  fillLinesColor: {
    innerLines: "#CCCCCC",
    config: {
      duration: 200,
    },
  },
  fillFeather: {
    featherColor: "#F2F2F2",
    config: {
      duration: 200,
    },
  },
  white: "#FFFFFF",
}

export const guaranteeAnimationConfig = {
  drawLine: {
    x: 0,
    yellowFigureColor: "#F8DF2A",
    config: {
      duration: 800,
    },
  },
  fillColor: {
    lineColor: "#3F3D56",
    config: {
      duration: 300,
    },
  },
  fillLinesColor: {
    innerLinesColor: "#CCCCCC",
    darkLinesColor: "#3F3D56",
    config: {
      duration: 300,
    },
  },
  white: "#FFFFFF",
}

export const sittingAnimationConfig = {
  drawLine: {
    x: 0,
    config: {
      duration: 900,
    },
  },
  fillLinesColor: {
    innerLines: "#CCCCCC",
    config: {
      duration: 200,
    },
  },
  white: "#FFFFFF",
}
