// extracted by mini-css-extract-plugin
export const heading = "offer-module--heading--1kb5e";
export const description__navy = "offer-module--description__navy--2UKo3";
export const description__white = "offer-module--description__white--3jnFr";
export const blocked = "offer-module--blocked--LUX4g";
export const loader = "offer-module--loader--1Sdq-";
export const offer__wrapper = "offer-module--offer__wrapper--3a0i0";
export const offer__wrapper__box__cta__coupon = "offer-module--offer__wrapper__box__cta__coupon--1WGr5";
export const offer__wrapper__box__cta__button__inner = "offer-module--offer__wrapper__box__cta__button__inner--vbhU3";
export const offer = "offer-module--offer--1YPn6";
export const offer__modal = "offer-module--offer__modal--3cncA";
export const offer__wrapper__box = "offer-module--offer__wrapper__box--drVNp";
export const offer__wrapper__box__checkbox = "offer-module--offer__wrapper__box__checkbox--1ANd3";
export const offer__wrapper__box__details = "offer-module--offer__wrapper__box__details--207wO";
export const offer__wrapper__box__details__prices = "offer-module--offer__wrapper__box__details__prices--3q02I";
export const offer__wrapper__box__details__headers = "offer-module--offer__wrapper__box__details__headers--34mhK";
export const offer__wrapper__box__details__headers__image = "offer-module--offer__wrapper__box__details__headers__image--OOdsl";
export const offer__wrapper__box__cta = "offer-module--offer__wrapper__box__cta--1HIHd";
export const offer__wrapper__box__cta__button = "offer-module--offer__wrapper__box__cta__button--2s3LT";
export const cart__item = "offer-module--cart__item--Ij_UC";
export const cart__item__divider = "offer-module--cart__item__divider--jS3ty";