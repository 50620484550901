import { useIntersectionObserver } from "@components/logic"
import React from "react"
import { animated, useSpring } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import animationConfig, { getAnimatedSvgProps } from "./animationConfig"
import { IAnimatedIcon } from "./icon"

const Community2: React.FC = (): JSX.Element => {
  const { pathRef, offset } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()

  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      {typeof offset === "number" && inView ? (
        <AnimatedIcon offset={offset} />
      ) : (
        <>
          <circle cx="45" cy="45" r="45" fill="#F8DF2A" />
          <path
            d="M68.2388 21.7558C59.282 12.7742 44.7401 12.7539 35.7586 21.7107C27.0922 30.353 26.7256 44.2729 34.9247 53.3595L33.1707 55.1135C31.7537 53.6962 29.456 53.6959 28.0388 55.113C28.0386 55.1132 28.0385 55.1133 28.0383 55.1135L16.0631 67.0887C14.6459 68.5058 14.6456 70.8034 16.0626 72.2207C16.0628 72.2208 16.0629 72.221 16.0631 72.2211L17.7735 73.9315C19.1906 75.3481 21.4876 75.3481 22.9047 73.9315L34.8799 61.9563C36.2971 60.5392 36.2974 58.2415 34.8804 56.8243C34.8802 56.8242 34.8801 56.824 34.8799 56.8239L36.6339 55.0699C46.0447 63.5746 60.568 62.8399 69.0727 53.4292C77.2875 44.3391 76.9205 30.4011 68.2388 21.7558ZM33.1707 60.2447L21.1955 72.2199C20.7231 72.6921 19.9575 72.6921 19.4851 72.2199L17.7747 70.5083C17.3032 70.0361 17.3032 69.2713 17.7747 68.7991L29.7499 56.8239C30.2283 56.3665 30.9819 56.3665 31.4603 56.8239L33.1707 58.5355C33.6422 59.0077 33.6422 59.7725 33.1707 60.2447ZM66.5296 52.5479C58.499 60.5782 45.4788 60.5781 37.4484 52.5474C29.418 44.5167 29.4182 31.4965 37.4489 23.4662C45.4794 15.436 58.4991 15.436 66.5296 23.4662C74.5471 31.5023 74.5471 44.5117 66.5296 52.5479Z"
            fill="#1B2029"
            ref={pathRef}
          />
        </>
      )}
    </svg>
  )
}

export default Community2

const AnimatedIcon: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const { fillColor, bgColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(animationConfig.bg)
      await next(animationConfig.draw)
      await next(animationConfig.fill)
    },
    from: {
      x: offset,
      fillColor: animationConfig.bg.bgColor,
      bgColor: animationConfig.white,
    },
  })

  return (
    <>
      <animated.circle cx="45" cy="45" r="45" fill={bgColor} />
      <animated.path
        d="M68.2388 21.7558C59.282 12.7742 44.7401 12.7539 35.7586 21.7107C27.0922 30.353 26.7256 44.2729 34.9247 53.3595L33.1707 55.1135C31.7537 53.6962 29.456 53.6959 28.0388 55.113C28.0386 55.1132 28.0385 55.1133 28.0383 55.1135L16.0631 67.0887C14.6459 68.5058 14.6456 70.8034 16.0626 72.2207C16.0628 72.2208 16.0629 72.221 16.0631 72.2211L17.7735 73.9315C19.1906 75.3481 21.4876 75.3481 22.9047 73.9315L34.8799 61.9563C36.2971 60.5392 36.2974 58.2415 34.8804 56.8243C34.8802 56.8242 34.8801 56.824 34.8799 56.8239L36.6339 55.0699C46.0447 63.5746 60.568 62.8399 69.0727 53.4292C77.2875 44.3391 76.9205 30.4011 68.2388 21.7558ZM33.1707 60.2447L21.1955 72.2199C20.7231 72.6921 19.9575 72.6921 19.4851 72.2199L17.7747 70.5083C17.3032 70.0361 17.3032 69.2713 17.7747 68.7991L29.7499 56.8239C30.2283 56.3665 30.9819 56.3665 31.4603 56.8239L33.1707 58.5355C33.6422 59.0077 33.6422 59.7725 33.1707 60.2447ZM66.5296 52.5479C58.499 60.5782 45.4788 60.5781 37.4484 52.5474C29.418 44.5167 29.4182 31.4965 37.4489 23.4662C45.4794 15.436 58.4991 15.436 66.5296 23.4662C74.5471 31.5023 74.5471 44.5117 66.5296 52.5479Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M64.8178 25.1763L63.1074 26.8867C69.2379 33.0321 69.2379 42.9801 63.1074 49.1255L64.8178 50.8359C71.8935 43.746 71.8935 32.2662 64.8178 25.1763Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
    </>
  )
}
