import React, { useState } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { IContactState } from "../../types"
import { AnimatedButton } from "@components/shared"
import { WPApi } from "@api"

import {
  contact__wrapper__msg,
  contact__wrapper__cta,
  contact__wrapper__content__form,
  contact__wrapper__content__form__item,
  contact__wrapper__cta__button,
} from "../styles/contact.module.scss"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[\s\p{L}]+$/u, {
      message: "Pole nie może zawierać cyfr i znaków specjalnych",
    })
    .required("Proszę wpisać imię i nazwisko"),
  message: Yup.string().required("Proszę wpisać wiadomość"),
  email: Yup.string()
    .email("Nieprawidłowy email")
    .required("Proszę wpisać email"),
})

const defaultState: IContactState = {
  imBusy: false,
  msg: "",
}
const defaultValues = {
  name: "",
  message: "",
  email: "",
}

const ContactForm = () => {
  const [state, setState] = useState(defaultState)
  const sendMessage = (email: string, name: string, message: string) => {
    setState({ ...state, imBusy: true, msg: "" })
    WPApi.sendMail(email, name, message).then((data) => {
      setState((prev) => {
        return {
          ...prev,
          imBusy: false,
          msg: data.message,
        }
      })
    })
  }

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        sendMessage(values.email, values.name, values.message)
        resetForm()
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className={contact__wrapper__content__form}>
            <div className={contact__wrapper__content__form__item}>
              <label>Twoja wiadomość</label>
              <Field
                name="message"
                as="textarea"
                rows={6}
                placeholder={"np. Ten zestaw mi naprawdę pomógł!"}
              />
              {errors.message && touched.message ? (
                <p>{errors.message}</p>
              ) : null}
            </div>
            <div className={contact__wrapper__content__form__item}>
              <label>Twoje imię i nazwisko</label>
              <Field name="name" type="text" placeholder={"np. Jan Kowalski"} />
              {errors.name && touched.name ? <p>{errors.name}</p> : null}
            </div>
            <div className={contact__wrapper__content__form__item}>
              <label>Twój adres email</label>
              <Field
                name="email"
                type="email"
                placeholder={"np. jan@kowalski.pl"}
              />
              {errors.email && touched.email ? <p>{errors.email}</p> : null}
            </div>
          </div>
          <div className={contact__wrapper__msg}>
            {state.msg ? <text>{state.msg}</text> : null}
          </div>
          <div className={contact__wrapper__cta}>
            <AnimatedButton
              className={contact__wrapper__cta__button}
              submit={true}
            >
              {state.imBusy ? "Wysyłanie wiadomości..." : "Wyślij wiadomość"}
            </AnimatedButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
