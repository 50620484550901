import React from "react"
import { Link } from "gatsby"
import { wrapper } from "./styles/section.module.scss"
import SittingSVG from "./icons/animated/Sitting"
import {
  programmer,
  programmer__wrapper,
  programmer__wrapper__content,
  programmer__wrapper__sitting,
  programmer__wrapper__content__button,
} from "./styles/programmer.module.scss"
import { AnimatedLink, AnimatedSection } from "@components/shared"

const header = "Hej, programisto!"
const paragraph =
  "Gladiatorzy Javascriptu to społeczność Newbie i Junior JS devów, którzy chcą wspólnie się uczyć pod okiem mentorów,"
const nextParagraph =
  "na praktycznych zadaniach, aby szybciej rozwijać skille koderskie."

const Programmer: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={wrapper}>
      <section className={programmer}>
        <div className={programmer__wrapper}>
          <div className={programmer__wrapper__content}>
            <h1>{header}</h1>
            <p>
              {paragraph} <br />
              {nextParagraph}
            </p>
            <AnimatedLink
              className={programmer__wrapper__content__button}
              to={"#gladiators"}
            >
              Zostań gladiatorem JS!
            </AnimatedLink>
          </div>
          <div className={programmer__wrapper__sitting}>
            <SittingSVG />
          </div>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Programmer
