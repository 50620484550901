import React from "react"
import { Link } from "gatsby"
import { wrapper } from "./styles/section.module.scss"

import {
  guarantee,
  guarantee__wrapper,
  guarantee__wrapper__headers,
  guarantee__wrapper__headers__button,
} from "./styles/guarantee.module.scss"

import GuaranteeSVG from "./icons/animated/Guarantee"
import { AnimatedButton, AnimatedSection } from "@components/shared"

const Guarantee: React.FC = (): JSX.Element => {
  return (
    <div className={wrapper}>
      <AnimatedSection className={guarantee}>
        <div className={guarantee__wrapper}>
          <div>
            <GuaranteeSVG />
          </div>
          <div className={guarantee__wrapper__headers}>
            <h1>Wygoda zakupu</h1>
            <p>
              Jeśli w przeciągu roku od zakupu zechcesz zrezygnować z nauki
              programowania, powiesz mi szczerze dlaczego nie chcesz zostać
              programistą, a ja zwrócę Ci pieniądze – programowanie jest trudne
              i nie jest dla wszystkich i doskonale to rozumiem 🙂.
            </p>
            {/* <AnimatedButton className={guarantee__wrapper__headers__button}>
              Kliknij, aby sprawdzić!
            </AnimatedButton> */}
          </div>
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Guarantee
