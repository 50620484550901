// extracted by mini-css-extract-plugin
export const heading = "problem-module--heading--fRkJk";
export const description__navy = "problem-module--description__navy--3IBnX";
export const description__white = "problem-module--description__white--1303Y";
export const blocked = "problem-module--blocked--1mlHt";
export const loader = "problem-module--loader--3cKQr";
export const problem__wrapper__headers = "problem-module--problem__wrapper__headers--185ux";
export const solving__wrapper__headers = "problem-module--solving__wrapper__headers--21zX2";
export const solving__wrapper__items__single = "problem-module--solving__wrapper__items__single--2TA6n";
export const questions__row__question = "problem-module--questions__row__question--eyRER";
export const questions__left = "problem-module--questions__left--2Fs8c";
export const questions__right = "problem-module--questions__right--cdTGi";
export const problem = "problem-module--problem--3HoO_";
export const problem__wrapper = "problem-module--problem__wrapper--8tbwm";
export const solving = "problem-module--solving--1bs9G";
export const solving__wrapper = "problem-module--solving__wrapper--2l4rK";
export const solving__wrapper__items = "problem-module--solving__wrapper__items--1vXLH";
export const questions = "problem-module--questions--3192V";
export const questions__row = "problem-module--questions__row--2hSZZ";