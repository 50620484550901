import React from "react"
import { wrapper } from "./styles/section.module.scss"

import { AnimatedSection, ItemSlider } from "@components/shared"
import {
  reviews,
  reviews__wrapper,
  reviewSlider,
} from "./styles/reviews.module.scss"

const reviewList = [
  {
    icon:
      "https://avatars2.githubusercontent.com/u/20260038?s=65&u=26db391b46529d824b20910236bd205f86956b64&v=4",
    title: "bartektelec",
    content:
      "“Serdecznie polecam dołączyć do Gladiatorów, zaczynając czułem że mam sporo braków i byłem niepewny wielu rzeczy związanych z programowaniem w JavaScript. Po tych 2 miesiącach pisanie testów stało się chlebem powszednim a pisanie w TypeScript, mimo iż było ciężkie na początku aktualnie stało się normą. Nie miałem pojęcia jak dużo może mi dać 2 godzinna rozmowa raz w tygodniu, na której wytyka mi się błędy i dyskutuje na różne tematy związane z programowanien. Zadania ze zbiorów są dopasowane do różnych poziomów zaawansowania i nauczyły mnie zdecydowanie programować tak jak robi się to w praktyce, ułatwiając sobie życie dobrymi praktykami.”",
  },
  {
    icon:
      "https://avatars3.githubusercontent.com/u/29304012?s=65&u=1d46cfae7a86d14db2ca2477b9bfe4591a4f2f51&v=4",
    title: "ireshka",
    content:
      "“Jeśli do tej pory nie miałeś/aś motywacji aby wskoczyć w nowy zakątek programistycznego świata czy dotyczy to testów jednostkowych, typescripta czy romansu z backendem - praca z zadaniami i Legion Gladiatorów Przemka jest do tego świetnym miejscem. Zadania są wymagające, ale mimo pojawiającej się momentami frustracji, satysfakcja gdy się uda - ogromna. A poziom trudności sprawia, że nigdy się nie stoi w miejscu i ciągle jest kolejny krok do zrobienia.”",
  },
  {
    icon:
      "https://avatars1.githubusercontent.com/u/35572075?s=65&u=d3df3ff0ad8a68e12ee4d141a88ee3f588fc9042&v=4",
    title: "Pierniki",
    content:
      "“Jako młodemu programiście, zawsze brakowało mi miejsca w którym będę mógł poćwiczyć zagadnienia nieco bardziej wymagające niż te ze studiów i kursów, jednocześnie otrzymując feedback na zasadzie 'baranie dlaczego to tak zrobiłeś, pomyśl teraz o przypadku takim i takim i wyciągnij wnioski', a nie '- działa proszę pana? -działa -ok'. Dużo można się dowiedzieć o clean code, o tym że tylko głupki nie robią walidacji, dojść do wniosku że Typescript nie taki straszny (wręcz przeciwnie) oraz doświadczyć pracy kilku/kilkunastu osobowym zespole nad ciekawym projektem. Według mnie świetne miejsce do rozwoju dla osób które podstawy mają już raczej obcykane i nie wiedzą w którą stronę iść dalej.”",
  },
  {
    title: "EmilMalanczak",
    icon:
      "https://avatars.githubusercontent.com/u/53233583?s=65&u=d9bdf1a5dfb1baaa31e63496ed6f8b054efa429e&v=4",
    content:
      "“ Porównując siebie sprzed i po dołączeniu do gladiatorów to mogę przyznać, że nauczyłem się więcej niż się spodziewałem. Teoretycznie posiadałem już jakąś wiedzę ale Przemek udowadniał mi za każdym razem, że jednak nie do końca. Bardzo dużo się nauczyłem przez te 2 miesiące, największym sukcesem myślę, że jest nauczenie się TypeScripta oraz nauczenie się pisania testów kodu. Polecam każdemu, kto chce dodać mocny improve w swoją naukę kodowania. ”",
  },
  {
    icon:
      "https://avatars.githubusercontent.com/u/30760855?s=65&u=12cf704185b79722ce8c5781689e4eac1b89c2cb&v=4",
    content:
      "“ Współpraca z Przemkiem pozwoliła mi na chwilę oderwać się od mało rozwojowego projektu i przeciwstawić stagnacji. Podczas 2 miesięcznych spotkań zacząłem doceniać wartość testów jednostkowych, ustrukturyzowałem wiedzę z JS’a jak również poznałem wiele wzorców projektowych. Każde spotkanie dawało mi dużego kopa motywacyjnego. Polecam. ”",
    title: "michalniem",
  },
  {
    icon: "https://avatars.githubusercontent.com/u/22132237?s=65&v=4",
    content:
      "“ Muszę przyznać, że pierwszy raz brałem udział w takiej inicjatywie jak Gladiatorzy JavaScriptu i nie wiedziałem czego się spodziewać. Zaskoczyłem się bardzo miło – okazało się, że formuła rozwiązywania zadań oraz wszelkiej pomocy, zarówno od Przemka jak i od innych osób z grupy zdaje egzamin. Spotkania code review pozwalały spojrzeć szerzej na omawiany problem, a dodatkowo wtrącane przez Przemka smaczki i ciekawostki czyniły ten czas bardzo wartościowym. Polecam wszystkim, którzy chcą podnieść swoje umiejętności kodowania, zrozumieć jak programowanie łączy się z biznesem, a także dla tych, którzy szukają nowych inspiracji.” ",
    title: "Piotr-Grzybowski",
  },
]

const Reviews: React.FC = (): JSX.Element => {
  return (
    <div className={wrapper}>
      <AnimatedSection className={reviews}>
        <div className={reviews__wrapper}>
          <ItemSlider
            items={reviewList}
            observeValue={(i) => i.title}
            name="reviews"
            className={reviewSlider}
          />
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Reviews
