import React from "react"
import { Link } from "gatsby"
import { wrapper } from "./styles/section.module.scss"

import {
  community,
  community__elements,
  community__elements__item,
  community__quote,
} from "./styles/community.module.scss"

import Community1 from "./icons/animated/Community1"
import Community2 from "./icons/animated/Community2"
import Community3 from "./icons/animated/Community3"
import { AnimatedSection } from "@components/shared"

const Community: React.FC = (): JSX.Element => {
  return (
    <div className={wrapper}>
      <AnimatedSection className={community}>
        <h1>Dołączając do społeczności Gladiatorów na Discordzie</h1>
        <div className={community__elements}>
          <div className={community__elements__item}>
            <Community1 />
            <p>Znajdziesz pomoc doświadczonego mentora podczas nauki.</p>
          </div>
          <div className={community__elements__item}>
            <Community2 />
            <p>
              Znajdziesz programistów na podobnym poziomie, z którymi będziesz
              mógł ćwiczyć swoje skile koderskie.
            </p>
          </div>
          <div className={community__elements__item}>
            <Community3 />
            <p>
              Znajdziesz aktualną wiedzę <br /> i rabaty na wartościowe kursy
              oraz kompanów do realizacji projektów do portfolio.
            </p>
          </div>
        </div>
        <div className={community__quote}>
          <p>
            Ta społeczność jest efektem pracy z ponad 100 programistów przez pół
            roku w różnych modelach nauki, po to aby znaleźć najlepszą formę
            nauczania.
          </p>
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Community
