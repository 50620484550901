// extracted by mini-css-extract-plugin
export const heading = "predecessors-module--heading--VWYDw";
export const description__navy = "predecessors-module--description__navy--DnCjp";
export const description__white = "predecessors-module--description__white--XIwyz";
export const blocked = "predecessors-module--blocked--2STDK";
export const loader = "predecessors-module--loader--1RNvN";
export const predecessors__wrapper__headers = "predecessors-module--predecessors__wrapper__headers--_rS6T";
export const predecessors = "predecessors-module--predecessors--1d4rn";
export const predecessors__wrapper = "predecessors-module--predecessors__wrapper--6D7Pt";
export const predecessors__wrapper__avatars = "predecessors-module--predecessors__wrapper__avatars--2-Mwm";
export const predecessors__wrapper__avatars__single = "predecessors-module--predecessors__wrapper__avatars__single--23Xf3";