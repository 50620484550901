import React, { useState, useEffect } from "react"
import { wrapper } from "./styles/section.module.scss"
import { WPApi } from "@api"
import "../../../loaderInit.modal.scss"
import {
  predecessors,
  predecessors__wrapper,
  predecessors__wrapper__headers,
  predecessors__wrapper__avatars,
  predecessors__wrapper__avatars__single,
} from "./styles/predecessors.module.scss"

import { AnimatedSection } from "@components/shared"
import { string } from "prop-types"
declare global {
  namespace NodeJS {
    interface Global {
      collaborators: any
    }
  }
}

interface User {
  url: string
  nick: string
}

const Predecessors: React.FC = (): JSX.Element => {
  const [state, setState] = useState({ users: [] })
  useEffect(() => {
    if (!global.collaborators) {
      WPApi.getCollaborators()
        .then((data) => {
          global.collaborators = data
          setState({
            users: data,
          })
        })
        .catch((err) => console.log(err))
    } else {
      setState({
        users: global.collaborators,
      })
    }
  }, [])

  return (
    <div className={wrapper}>
      <AnimatedSection className={predecessors}>
        <div className={predecessors__wrapper}>
          <div className={predecessors__wrapper__headers}>
            <h1>
              To część społeczności, która regularnie pracuje rozwijając się
              przy zadaniach!
            </h1>
            <p>
              Przewodzę tej społeczności i daję całą swoją 10 letnią wiedzę
              <br /> i doświadczenie dla dobra nowych pokoleń programistów JS
            </p>
          </div>
          <div className={predecessors__wrapper__avatars}>
            {state.users.length !== 0 ? (
              state.users.map((user: User, i: number) => {
                return (
                  <div
                    className={predecessors__wrapper__avatars__single}
                    key={i}
                  >
                    <img loading="lazy" src={user.url} alt="github"></img>
                    <p>{user.nick}</p>
                  </div>
                )
              })
            ) : (
              <div className="loaderInit" />
            )}
          </div>
        </div>
      </AnimatedSection>
    </div>
  )
}

export default Predecessors
