import { useRef, useState, useEffect } from "react"

export default () => {
  const pathRef = useRef<SVGPathElement | null>(null)
  const [offset, setOffset] = useState<number | null>(null)

  useEffect(() => {
    if (!pathRef.current) {
      return
    }

    if (pathRef.current.getTotalLength()) {
      setOffset(pathRef.current.getTotalLength())
    }
  }, [pathRef])

  return { offset, pathRef }
}
