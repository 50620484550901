import React, { useEffect, useRef, useState, useContext } from "react"
import { wrapper } from "./styles/section.module.scss"
import {
  ResizedImage,
  Modal,
  AnimatedLink,
  AnimatedButton,
  AnimatedSection,
} from "@components/shared"
import { ProductModal } from "@components/ecommerce"
import { withHtml } from "@components/logic"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import { WPApi } from "@api"
import AddHomeProduct from "../Home/common/AddHomeProduct"
import AllBooks from "./icons/allbooks.svg"

import { StructuredData } from "@components/layout"

import {
  offer,
  offer__modal,
  offer__wrapper,
  offer__wrapper__box,
  offer__wrapper__box__details,
  offer__wrapper__box__details__headers__image,
  offer__wrapper__box__details__headers,
  offer__wrapper__box__details__prices,
  offer__wrapper__box__cta,
  offer__wrapper__box__cta__button__inner,
  offer__wrapper__box__cta__coupon,
  offer__wrapper__box__cta__button,
  offer__wrapper__products__item,
  offer__wrapper__products__item__img,
  offer__wrapper__products__item__prices,
  offer__wrapper__products__item__button,
  offer__wrapper__box__checkbox,
} from "./styles/offer.module.scss"

const Description = withHtml("span", "span")
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const prepareProduct = (product) => {
  return {
    value: cleanPrice(product.salePrice),
    product_id: product.productId,
    name: product.name,
    slug: product.slug,
    price: cleanPrice(product.salePrice),
    regularPrice: cleanPrice(product.regularPrice),
    regularValue: cleanPrice(product.regularPrice),
    value: cleanPrice(product.salePrice),
    quantity: 1,
    image: product.image,
  }
}

const Offer: React.FC = ({ allProducts }): JSX.Element => {
  const [state, setState] = useState({ coupons: [], discountValue: 0 })
  const { dispatch } = useContext(CartCtx)
  const { coupon, items } = useContextState(CartCtx, ["coupon", "items"])
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const modal = useRef()
  const [checked, setChecked] = useState(false)
  const allTasks = allProducts.filter((el) => el.productId === 1837)[0]
  const gladiatorsCommunity = allProducts.filter(
    (el) => el.productId === 1838
  )[0]
  const { productId, salePrice, regularPrice, quantity } = allTasks
  const allTaksPrepared = prepareProduct(allTasks)
  const gladiatorsPrepared = prepareProduct(gladiatorsCommunity)

  useEffect(() => {
    if (checked) {
      dispatch({
        type: "removeFromCart",
        payload: gladiatorsPrepared,
      })
    } else {
      dispatch({
        type: "resetCart",
      })
      dispatch({
        type: "addToCart",
        payload: gladiatorsPrepared,
      })
      dispatch({
        type: "addToCart",
        payload: allTaksPrepared,
      })
    }
  }, [checked])

  let offerProduct = [
    {
      product_id: productId,
      quantity: 1,
    },
  ]
  useEffect(() => {
    if (coupon.length > 5) {
      WPApi.getDiscount(items, coupon).then((data) => {
        setState({
          ...state,
          discountValue: data.discountValue,
        })
      })
    } else {
      setState({
        ...state,
        discountValue: 0,
      })
    }
  }, [coupon, items])

  return (
    <React.Fragment>
      <div className={wrapper}>
        <section className={offer}>
          <div className={offer__wrapper} id="gladiators">
            <h1>Dołącz do nas i zacznij skuteczniej się uczyć</h1>
            <p>
              Jednorazowa inwestycja w komplet materiałów do nauki dożywotnio
              pozwala uczestniczyć w życiu społeczności!
            </p>
            <AnimatedSection className={offer__wrapper__box}>
              <div className={offer__wrapper__box__details}>
                <div className={offer__wrapper__box__details__headers}>
                  <div className={offer__wrapper__box__details__headers__image}>
                    <img src={AllBooks} alt="all-books"></img>
                  </div>
                  <h2>Zbiór zadań Gladiatorów Javascriptu</h2>
                </div>
              </div>
              <div className={offer__wrapper__box__details__prices}>
                <h3>{cleanPrice(salePrice) + (checked ? 0 : 40)} zł</h3>
              </div>
              <div
                className={offer__wrapper__box__checkbox}
                onClick={() => setChecked((prev) => !prev)}
              >
                <input type="checkbox" checked={checked} />
                <small>
                  Świadomie rezygnuję z możliwości dołączenia do społeczności
                  Gladiatorów (-40zł).
                </small>
              </div>
              <div className={offer__wrapper__box__cta}>
                <div className={offer__wrapper__box__cta__coupon}>
                  {state.discountValue !== 0 ? (
                    <h6>
                      Wartość kuponu: {state.discountValue.toFixed(2)} PLN
                    </h6>
                  ) : (
                    <h6>&nbsp;</h6>
                  )}
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Miejsce na Twój kupon
                  </p>
                  <input
                    type={"text"}
                    placeholder={"np. b73hsa2k56"}
                    value={coupon}
                    onChange={(ev) => {
                      setCoupon(ev.target.value)
                    }}
                  ></input>
                </div>
                <div className={offer__wrapper__box__cta__button}>
                  <AddHomeProduct
                    label={"aaa"}
                    {...allTasks}
                    className={offer__wrapper__box__cta__button__inner}
                    modal={modal}
                  />
                </div>
              </div>
            </AnimatedSection>
          </div>
        </section>
      </div>
      <Modal title={""} ref={modal} className={offer__modal}>
        <ProductModal {...allTasks} />
      </Modal>
      <StructuredData>
        {{
          "@context": "http://schema.org/",
          "@type": "Product",
          name: allTaksPrepared.name,
          image: allTaksPrepared.image,
          description: "Rozwijajace zadania JavaScript i React",
          sku: " ",
          brand: {
            "@type": "Thing",
            name: "Localhost Group",
            url: "https://gladiators-of-javascript.com",
          },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: "5",
            reviewCount: "387",
          },
          offers: {
            "@type": "Offer",
            priceCurrency: "PLN",
            price: allTaksPrepared.price,
            priceValidUntil: "2021-01-25",
            url: "https://gladiators-of-javascript.com",
            availability: "http://schema.org/InStock",
            seller: {
              "@type": "Organization",
              name: "Localhost Group",
            },
          },
        }}
      </StructuredData>
    </React.Fragment>
  )
}

export default Offer
