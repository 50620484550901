import React from "react"
import { animated, useSpring } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import { IAnimatedIcon } from "./icon"
import animationConfig, { getAnimatedSvgProps } from "./animationConfig"
import { useIntersectionObserver } from "@components/logic"
const Gladiators3: React.FC = (): JSX.Element => {
  const { offset, pathRef } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      {typeof offset === "number" && inView ? (
        <AnimatedIcon offset={offset} />
      ) : (
        <path
          d="M66 61H64.035C67.1719 57.9505 68.6992 53.6077 68.162 49.266L65.413 27.266C65.2154 25.6616 64.7371 24.1046 64 22.666V22C63.9956 18.1359 60.8641 15.0044 57 15H33C29.1359 15.0044 26.0044 18.1359 26 22V22.666C25.263 24.1046 24.785 25.6616 24.588 27.266L21.838 49.266C21.3007 53.6077 22.8281 57.9505 25.965 61H24C22.3431 61 21 62.3431 21 64V70C21 71.6569 22.3431 73 24 73H26.184C26.6062 74.1944 27.7333 74.9948 29 75H61C62.2668 74.9948 63.3938 74.1944 63.816 73H66C67.6569 73 69 71.6569 69 70V64C69 62.3431 67.6569 61 66 61ZM26 71H24C23.4478 71 23 70.5522 23 70V64C23 63.4478 23.4478 63 24 63H26V71ZM26 58C24.229 55.5476 23.451 52.5161 23.822 49.514L26 32.094V58ZM62 72C62 72.5522 61.5522 73 61 73H29C28.4478 73 28 72.5522 28 72V46H62V72ZM32 44V22C32 21.4478 32.4478 21 33 21H57C57.5522 21 58 21.4478 58 22V44H32ZM62 44H60V22C60 20.3431 58.6569 19 57 19H33C31.3431 19 30 20.3431 30 22V44H28V22C28.0033 19.24 30.24 17.0032 33 17H57C59.76 17.0032 61.9967 19.24 62 22V44ZM64 32.1L66.178 49.514C66.549 52.5161 65.771 55.5476 64 58V32.1ZM67 70C67 70.5522 66.5522 71 66 71H64V63H66C66.5522 63 67 63.4478 67 64V70Z"
          ref={pathRef}
          fill="#1B2029"
        />
      )}
    </svg>
  )
}

const AnimatedIcon: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const { bgColor, fillColor, x }: any = useSpring({
    to: async (next: any) => {
      await next(animationConfig.bg)
      await next(animationConfig.draw)
      await next(animationConfig.fill)
    },
    from: {
      x: offset,
      fillColor: animationConfig.bg.bgColor,
      bgColor: animationConfig.white,
    },
  })
  return (
    <>
      <animated.circle cx="45" cy="45" r="45" fill={bgColor} />
      <animated.path
        d="M66 61H64.035C67.1719 57.9505 68.6992 53.6077 68.162 49.266L65.413 27.266C65.2154 25.6616 64.7371 24.1046 64 22.666V22C63.9956 18.1359 60.8641 15.0044 57 15H33C29.1359 15.0044 26.0044 18.1359 26 22V22.666C25.263 24.1046 24.785 25.6616 24.588 27.266L21.838 49.266C21.3007 53.6077 22.8281 57.9505 25.965 61H24C22.3431 61 21 62.3431 21 64V70C21 71.6569 22.3431 73 24 73H26.184C26.6062 74.1944 27.7333 74.9948 29 75H61C62.2668 74.9948 63.3938 74.1944 63.816 73H66C67.6569 73 69 71.6569 69 70V64C69 62.3431 67.6569 61 66 61ZM26 71H24C23.4478 71 23 70.5522 23 70V64C23 63.4478 23.4478 63 24 63H26V71ZM26 58C24.229 55.5476 23.451 52.5161 23.822 49.514L26 32.094V58ZM62 72C62 72.5522 61.5522 73 61 73H29C28.4478 73 28 72.5522 28 72V46H62V72ZM32 44V22C32 21.4478 32.4478 21 33 21H57C57.5522 21 58 21.4478 58 22V44H32ZM62 44H60V22C60 20.3431 58.6569 19 57 19H33C31.3431 19 30 20.3431 30 22V44H28V22C28.0033 19.24 30.24 17.0032 33 17H57C59.76 17.0032 61.9967 19.24 62 22V44ZM64 32.1L66.178 49.514C66.549 52.5161 65.771 55.5476 64 58V32.1ZM67 70C67 70.5522 66.5522 71 66 71H64V63H66C66.5522 63 67 63.4478 67 64V70Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M56 55H34C33.4477 55 33 55.4477 33 56V70C33 70.5522 33.4477 71 34 71H56C56.5522 71 57 70.5522 57 70V56C57 55.4477 56.5522 55 56 55ZM55 69H35V57H55V69Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M30 48V50H57V53H59V50H60V48H30Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
      <animated.path
        d="M34 23V25H53V28H55V25H56V23H34Z"
        {...getAnimatedSvgProps(fillColor, x, offset)}
      />
    </>
  )
}

export default Gladiators3
