import React from "react"
import { wrapper } from "./styles/section.module.scss"
import ContactForm from "../Home/common/ContactForm"
import ContactSVG from "./icons/animated/Contact"
import {
  contact,
  contact__wrapper,
  contact__wrapper__headers,
  contact__wrapper__content,
} from "./styles/contact.module.scss"
import { AnimatedSection } from "@components/shared"

const Contact: React.FC = (): JSX.Element => {
  return (
    <AnimatedSection className={wrapper}>
      <section className={contact}>
        <div id="contact" style={{ position: "absolute", top: "-200px" }} />
        <div className={contact__wrapper}>
          <div className={contact__wrapper__headers}>
            <h1>Skontaktuj się ze mną!</h1>
            <p>Jeśli masz jakieś pytania lub wątpliwości - Napisz do mnie!</p>
          </div>
          <div className={contact__wrapper__content}>
            <ContactSVG />
            <ContactForm />
          </div>
        </div>
      </section>
    </AnimatedSection>
  )
}

export default Contact
