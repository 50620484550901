import React from "react"
import { animated, useSpring } from "react-spring"
import useSvgOffset from "../../common/useSvgOffset"
import { IAnimatedIcon } from "./icon"
import animationConfig, {
  sittingAnimationConfig,
  sittingSvgProps,
} from "./animationConfig"
import { useIntersectionObserver } from "@components/logic"

const Gladiators3: React.FC = (): JSX.Element => {
  const { offset, pathRef } = useSvgOffset()

  const [{ inView }, marktoObserve] = useIntersectionObserver()
  return (
    <svg
      width="500"
      height="479"
      viewBox="0 0 500 479"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={marktoObserve}
    >
      <path
        d="M449.651 413.591H102.363C100.802 413.589 99.306 413.07 98.2025 412.147C97.099 411.224 96.4783 409.972 96.4765 408.667V229.756C96.4783 228.451 97.099 227.199 98.2025 226.276C99.306 225.353 100.802 224.834 102.363 224.832H449.651C451.211 224.834 452.707 225.353 453.811 226.276C454.914 227.199 455.535 228.451 455.537 229.756V408.667C455.535 409.972 454.914 411.224 453.811 412.147C452.707 413.07 451.211 413.589 449.651 413.591ZM102.363 225.927C101.149 225.928 99.9853 226.332 99.127 227.05C98.2688 227.768 97.786 228.741 97.7846 229.756V408.667C97.786 409.682 98.2688 410.655 99.127 411.373C99.9853 412.091 101.149 412.495 102.363 412.496H449.651C450.864 412.495 452.028 412.091 452.886 411.373C453.745 410.655 454.227 409.682 454.229 408.667V229.756C454.227 228.741 453.745 227.768 452.886 227.05C452.028 226.332 450.864 225.928 449.651 225.927H102.363Z"
        fill="#3F3D56"
      />

      <path
        d="M105.043 228.318H171.988C173.613 228.318 174.93 227.001 174.93 225.378V209.943C174.93 208.319 173.613 207.003 171.988 207.003H105.043C103.418 207.003 102.1 208.319 102.1 209.943V225.378C102.1 227.001 103.418 228.318 105.043 228.318Z"
        fill="#2F2E41"
      />
      <path
        d="M170.517 206.268H68.9961C68.4164 206.268 67.8424 206.382 67.3069 206.603C66.7714 206.825 66.2848 207.15 65.8749 207.559C65.465 207.969 65.1399 208.455 64.9181 208.99C64.6963 209.525 64.5821 210.099 64.5822 210.678V280.502C64.5822 281.672 65.0472 282.794 65.875 283.621C66.7028 284.448 67.8254 284.912 68.9961 284.912H81.5022C82.6729 284.912 83.7956 284.448 84.6233 283.621C85.4511 282.794 85.9161 281.672 85.9162 280.502V227.583H102.101V281.237C102.101 282.407 102.566 283.529 103.393 284.356C104.221 285.183 105.344 285.647 106.515 285.647H119.021C120.191 285.647 121.314 285.183 122.142 284.356C122.97 283.529 123.435 282.407 123.435 281.237V227.583H170.517C171.687 227.583 172.81 227.118 173.638 226.291C174.465 225.464 174.931 224.342 174.931 223.173V210.678C174.931 210.099 174.816 209.525 174.595 208.99C174.373 208.455 174.048 207.969 173.638 207.559C173.228 207.15 172.741 206.825 172.206 206.603C171.67 206.382 171.096 206.268 170.517 206.268Z"
        fill="#2F2E41"
      />
      <path
        d="M195.999 228.729L99.5166 226.7C98.8614 226.686 98.2175 226.527 97.6317 226.233C97.0458 225.94 96.5327 225.52 96.1296 225.004C95.7264 224.487 95.4434 223.888 95.301 223.249C95.1586 222.61 95.1605 221.947 95.3064 221.309L118.798 118.556C119.02 117.582 119.567 116.713 120.349 116.09C121.131 115.467 122.101 115.128 123.101 115.128H148.363C156.669 115.128 164.72 117.984 171.166 123.217C177.612 128.449 182.058 135.739 183.759 143.86L200.412 223.417C200.548 224.067 200.536 224.739 200.376 225.383C200.216 226.027 199.912 226.626 199.488 227.137C199.064 227.648 198.53 228.056 197.926 228.332C197.322 228.607 196.663 228.743 195.999 228.729Z"
        fill="#2F2E41"
      />
      <path
        d="M136.249 105.967C157.994 105.967 175.621 88.3551 175.621 66.6302C175.621 44.9053 157.994 27.2938 136.249 27.2938C114.505 27.2938 96.8776 44.9053 96.8776 66.6302C96.8776 88.3551 114.505 105.967 136.249 105.967Z"
        fill="#F8DF2A"
      />
      <path
        d="M118.388 45.4501C113.792 44.3672 108.831 42.9101 105.553 39.2953C102.763 36.2181 101.454 31.469 103.927 27.8208C106.185 24.4896 110.638 23.2673 114.386 22.6426C118.743 21.9165 123.22 21.8105 127.308 19.9579C129.316 19.0508 131.067 17.6623 132.408 15.9157C133.652 14.1698 134.732 12.3126 135.634 10.3679C136.489 8.47248 137.631 6.72003 139.02 5.17223C140.6 3.62685 142.637 2.6331 144.828 2.33927C149.76 1.59031 154.442 3.86764 158.323 6.69572C162.082 9.48821 165.491 12.7207 168.479 16.3241C175.264 24.4026 179.934 34.0382 182.071 44.3657C182.358 45.7545 184.486 45.1667 184.199 43.7795C182.156 34.0061 177.912 24.8257 171.79 16.9349C168.757 13.0004 165.274 9.43422 161.412 6.30931C157.412 3.0906 152.795 0.285476 147.535 0.019665C145.103 -0.121897 142.675 0.359816 140.481 1.4191C138.465 2.48069 136.769 4.05935 135.566 5.99288C133.088 9.74819 131.892 14.6009 127.894 17.1411C123.844 19.7142 118.773 19.7395 114.179 20.4555C110.092 21.0922 105.543 22.4371 102.777 25.7158C99.7167 29.3425 99.8318 34.2846 102.117 38.2844C104.657 42.7286 109.375 45.154 114.113 46.6C115.33 46.9713 116.563 47.2845 117.801 47.5763C119.182 47.9018 119.772 45.7762 118.388 45.4501V45.4501Z"
        fill="#2F2E41"
      />
      <path
        d="M140.503 79.7641C138.071 79.6959 135.043 79.6112 132.713 77.9101C132.018 77.3825 131.448 76.7096 131.041 75.9385C130.635 75.1674 130.402 74.3168 130.359 73.4463C130.317 72.8278 130.418 72.2079 130.656 71.6352C130.893 71.0624 131.26 70.5522 131.727 70.1445C132.945 69.116 134.723 68.8741 136.64 69.4383L134.655 54.9393L136.112 54.7397L138.447 71.7846L137.229 71.2269C135.817 70.5795 133.881 70.2515 132.677 71.2671C132.381 71.5327 132.151 71.8628 132.004 72.2318C131.857 72.6008 131.797 72.9987 131.829 73.3946C131.865 74.0425 132.039 74.6751 132.342 75.2494C132.644 75.8237 133.066 76.3263 133.58 76.7229C135.394 78.0464 137.807 78.218 140.544 78.2941L140.503 79.7641Z"
        fill="#2F2E41"
      />
      <path
        d="M127.446 56.1896H119.521V57.6596H127.446V56.1896Z"
        fill="#2F2E41"
      />
      <path
        d="M152.458 56.1896H144.534V57.6596H152.458V56.1896Z"
        fill="#2F2E41"
      />
      <path
        d="M60.9405 190.27C60.022 190.27 59.1228 190.007 58.3499 189.511C57.5771 189.015 56.9634 188.308 56.5818 187.473L52.0803 177.601C51.8199 177.03 51.6747 176.414 51.6529 175.787C51.6311 175.16 51.7331 174.534 51.9531 173.947C52.1731 173.359 52.5068 172.821 52.9352 172.362C53.3635 171.903 53.8781 171.533 54.4496 171.273L136.802 133.789C137.957 133.265 139.272 133.221 140.459 133.664C141.647 134.108 142.609 135.004 143.136 136.156L147.638 146.029C148.162 147.182 148.207 148.496 147.763 149.682C147.319 150.868 146.422 151.83 145.269 152.357L62.9157 189.84C62.2957 190.123 61.6221 190.27 60.9405 190.27Z"
        fill="#F8DF2A"
      />

      {typeof offset === "number" && inView ? (
        <AnimatedIcon offset={offset} />
      ) : (
        <path
          d="M393.698 268.714H156.081C155.203 268.714 154.361 268.366 153.74 267.745C153.119 267.125 152.77 266.284 152.77 265.407C152.77 264.529 153.119 263.688 153.74 263.068C154.361 262.448 155.203 262.099 156.081 262.099H393.698C394.576 262.099 395.418 262.448 396.038 263.068C396.659 263.688 397.008 264.529 397.008 265.407C397.008 266.284 396.659 267.125 396.038 267.745C395.418 268.366 394.576 268.714 393.698 268.714Z"
          ref={pathRef}
          fill="#CCCCCC"
        />
      )}
    </svg>
  )
}

const AnimatedIcon: React.FC<IAnimatedIcon> = ({ offset }): JSX.Element => {
  const { innerLines, x }: any = useSpring({
    to: async (next: any) => {
      await next(sittingAnimationConfig.drawLine)
      await next(sittingAnimationConfig.fillLinesColor)
    },
    from: {
      x: offset,
      innerLines: animationConfig.white,
    },
  })
  return (
    <>
      <animated.path
        d="M393.698 268.714H156.081C155.203 268.714 154.361 268.366 153.74 267.745C153.119 267.125 152.77 266.284 152.77 265.407C152.77 264.529 153.119 263.688 153.74 263.068C154.361 262.448 155.203 262.099 156.081 262.099H393.698C394.576 262.099 395.418 262.448 396.038 263.068C396.659 263.688 397.008 264.529 397.008 265.407C397.008 266.284 396.659 267.125 396.038 267.745C395.418 268.366 394.576 268.714 393.698 268.714Z"
        {...sittingSvgProps(innerLines, x, offset)}
      />
      <animated.path
        d="M393.698 295.909H156.081C155.203 295.909 154.361 295.56 153.74 294.94C153.119 294.32 152.77 293.479 152.77 292.601C152.77 291.724 153.119 290.883 153.74 290.263C154.361 289.642 155.203 289.294 156.081 289.294H393.698C394.576 289.294 395.418 289.642 396.038 290.263C396.659 290.883 397.008 291.724 397.008 292.601C397.008 293.479 396.659 294.32 396.038 294.94C395.418 295.56 394.576 295.909 393.698 295.909Z"
        {...sittingSvgProps(innerLines, x, offset)}
      />
      <animated.path
        d="M393.698 323.104H156.081C155.203 323.104 154.361 322.755 153.74 322.135C153.119 321.515 152.77 320.673 152.77 319.796C152.77 318.919 153.119 318.078 153.74 317.457C154.361 316.837 155.203 316.489 156.081 316.489H393.698C394.576 316.489 395.418 316.837 396.038 317.457C396.659 318.078 397.008 318.919 397.008 319.796C397.008 320.673 396.659 321.515 396.038 322.135C395.418 322.755 394.576 323.104 393.698 323.104Z"
        {...sittingSvgProps(innerLines, x, offset)}
      />
      <animated.path
        d="M393.698 350.299H156.081C155.203 350.299 154.361 349.95 153.74 349.33C153.119 348.71 152.77 347.868 152.77 346.991C152.77 346.114 153.119 345.273 153.74 344.652C154.361 344.032 155.203 343.684 156.081 343.684H393.698C394.576 343.684 395.418 344.032 396.038 344.652C396.659 345.273 397.008 346.114 397.008 346.991C397.008 347.868 396.659 348.71 396.038 349.33C395.418 349.95 394.576 350.299 393.698 350.299Z"
        {...sittingSvgProps(innerLines, x, offset)}
      />
      <animated.path
        d="M393.698 377.493H156.081C155.203 377.493 154.361 377.145 153.74 376.525C153.119 375.904 152.77 375.063 152.77 374.186C152.77 373.309 153.119 372.467 153.74 371.847C154.361 371.227 155.203 370.878 156.081 370.878H393.698C394.576 370.878 395.418 371.227 396.038 371.847C396.659 372.467 397.008 373.309 397.008 374.186C397.008 375.063 396.659 375.904 396.038 376.525C395.418 377.145 394.576 377.493 393.698 377.493Z"
        {...sittingSvgProps(innerLines, x, offset)}
      />
    </>
  )
}

export default Gladiators3
