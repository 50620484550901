// extracted by mini-css-extract-plugin
export const heading = "programmer-module--heading--3wbYb";
export const description__navy = "programmer-module--description__navy--3VWzH";
export const description__white = "programmer-module--description__white--3xCzh";
export const blocked = "programmer-module--blocked--Dq6YM";
export const loader = "programmer-module--loader--2TNdr";
export const programmer__wrapper__content = "programmer-module--programmer__wrapper__content--vlIG0";
export const programmer__wrapper__content__button = "programmer-module--programmer__wrapper__content__button--28nls";
export const programmer = "programmer-module--programmer--34XC4";
export const programmer__wrapper = "programmer-module--programmer__wrapper--tWzBP";
export const programmer__wrapper__sitting = "programmer-module--programmer__wrapper__sitting--337i2";